<template>
  <ModalWrapper
    :show-backdrop="$store.state.modals.nearbyPlacesModalVisibility"
    @closeModal="close"
  >
    <div class="choose-modal_wrap nearby_places_modal">
      <div class="header_modal">
        <div class="close" @click="close"></div>
        <h2 class="modal_title">Ближайшие места</h2>
      </div>
      <div class="modal_content">
        <div class="check_item" v-for="(place, i) in nearbyPlaces" :key="i">
          <label>
            <input
              type="checkbox"
              :value="i"
              :checked="i in checkedPlaces"
              v-model="checkedPlaces"
            />
            <span class="checkmark"></span>
            <span class="nearby_places_name">{{ place.text_r }}</span>
          </label>
        </div>
      </div>
      <div class="buttons_modal footer_modal">
        <button class="cancel_button" @click="close">Отменить</button>
        <button class="submitted_btn" @click="save">Готово</button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapState } from 'vuex'
import ModalWrapper from '@/components/ModalWrapper'
import {
  SET_MODAL_VISIBILITY_MUTATION,
  UPDATE_NEW_STATION_ACTION
} from '@/store/constants'
import { nearbyPlaces } from '@/data/nearbyPlaces'

export default {
  name: 'ChooseNearbyPlacesModal',
  components: {
    ModalWrapper
  },
  data: () => ({
    checkedPlaces: []
  }),
  beforeMount  () {
    if (this.newStation.nearbyPlaces.length > 0) {
      this.checkedPlaces = this.newStation.nearbyPlaces
    }
  },
  methods: {
    save () {
      this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
        updatedStationField: 'nearbyPlaces',
        values: this.checkedPlaces
      })

      this.close()
    },
    close () {
      this.$store.commit(SET_MODAL_VISIBILITY_MUTATION, {
        modalName: 'nearbyPlacesModalVisibility',
        flag: false
      })
    }
  },
  computed: {
    ...mapState(['newStation']),
    nearbyPlaces () {
      return nearbyPlaces
    }
  }
}
</script>

<style scoped lang="scss">
</style>
