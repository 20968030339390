<template>
  <select
    name="from_time"
    id="from_time"
    v-model="day.workingHours[hoursItem][period]"
    @change="timeHandler"
  >
    <option
      v-if="period === 'from' && hoursItem === 0"
      value="fullTime">
      Круглосуточно
    </option>
    <option
      v-for="(hour, i) in hoursIterator"
      :value="hour"
      :key="i"
    >
      {{ `${hour}:00` }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'ScheduleSelect',
  props: {
    day: Object,
    period: String,
    hoursItem: Number
  },
  methods: {
    timeHandler (e) {
      const value = e.target.value
      if (value === 'fullTime') {
        this.day.fullTime = true
        this.$emit('delete-working-hours', this.day)
      } else {
        this.day.fullTime = false
      }
    }
  },
  computed: {
    hoursIterator () {
      const hours = []
      let fromHour = 0
      let toHour = 25

      if (this.period === 'from') {
        fromHour = this.hoursItem
          ? this.day.workingHours[this.hoursItem - 1].to + 1
          : 0

        toHour = this.day.workingHours[this.hoursItem].to
      } else {
        fromHour = this.day.workingHours[this.hoursItem].from + 1

        toHour = this.day.workingHours.length > 1 && !this.hoursItem
          ? this.day.workingHours[this.hoursItem + 1].from
          : 25
      }

      for (let i = fromHour; i < toHour; i++) {
        hours.push(i)
      }

      return hours
    }
  }
}
</script>

<style scoped>

</style>
