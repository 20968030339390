<template>
  <div class="add-station">
    <div class="add-station__close" @click="close">
      <img src="../../../../assets/img/close.svg" alt="close">
    </div>
    <div class="add-station__container">
      <div class="add-station__item item__head">
        <h3 class="head__heading">{{ $t('new_station_sidebar.head.title') }}</h3>
        <p class="head__text">{{ $t('new_station_sidebar.head.description') }}</p>
      </div>
      <div class="add-station__item item__station-info">
        <Schedule />
        <NearbyPlaces />
      </div>
      <Connectors :showRequiredFields="showRequiredFields" />
      <Photos />
      <Description
        :showRequiredFields="showRequiredFields"
        @url-checker="(val) => this.url = val"
      />
      <div class="add-station__item item__save">
        <a href="#" class="save__cancel" @click="close">Отменить</a>
        <a href="#" class="save__ready" @click="send">Готово</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Schedule from './EditSchedule'
import NearbyPlaces from './EditNearbyPlaces'
import Connectors from './EditConnectors'
import Photos from './EditPhotos'
import Description from './EditDescription'
import De from '../../../../i18n/de'
import {
  // SEND_STATION_ACTION,
  SET_NEW_STATION_MUTATION
} from '@/store/constants'

export default {
  name: 'EditStationInfo',
  components: {
    De,
    Connectors,
    Schedule,
    Photos,
    NearbyPlaces,
    Description
  },
  data: () => ({
    showRequiredFields: false,
    url: false
  }),
  methods: {
    send () {
      if (this.validation()) {
        // this.$store.dispatch(SEND_STATION_ACTION)

        this.close()
      } else {
        this.showRequiredFields = true
      }
    },
    validation () {
      const { name, connectors, site } = this.newStation

      if (!name || !connectors.length) {
        return false
      }

      return !(site && !this.url)
    },
    close () {
      this.$store.commit(SET_NEW_STATION_MUTATION, null)
    }
  },
  computed: mapState(['newStation'])
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/includes/mixin_scroll_reset";
$green: #5ab464;
$grey: #545759;

// main styles
.add-station {
  position: absolute;
  top: 86px;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 408px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  @media (max-width: 1250px) {
    top: 62px;
  }
  @media (max-width: 575px) {
    top: 30%;
    width: 95%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 0 auto;
    max-width: none;
    left: 50%;
    transform: translateX(-50%);
  }

  &__container {
    height: 100%;
    overflow-y: scroll;
    @include scrollReset(none, #5db45d);
    @media (max-width: 575px) {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: -40px;
    width: 40px;
    height: 40px;
    border-radius: 0 10px 10px 0;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
    z-index: 1;
    @media (max-width: 575px) {
      right: 0;
      border-radius: 0;
      box-shadow: none;
      background: transparent;
    }

    img {
      width: 13px;
      height: 13px;
    }
  }

  &__item {
    border-bottom: 1px solid rgba(84, 87, 89, 0.5);
    padding: 7px 20px 20px;

    .item__heading {
      font-size: 16px;
      font-weight: 700;
    }
  }
  &__item.item__head {
    padding: 10px 20px;
    color: #fff;
    background: url(../../../../assets/img/edit_station_bg.jpg) no-repeat center center/cover;
    border-bottom: none;
    @media (max-width: 575px) {
      padding-right: 50px;
    }
  }
  &__item.item__station-info {
    padding-top: 15px;
  }
  &__item.item__save {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: none;
  }
}

// head
.head__heading {
  font-size: 22px;
  font-weight: 800;
  padding-bottom: 5px;
  line-height: 35px;
}
.head__text {
  font-size: 14px;
  line-height: 17px;
}

// save
.save__cancel {
  color: $green;
  font-size: 14px;
  margin-right: 25px;
}
.save__ready {
  padding: 7px 25px;
  font-size: 14px;
  color: #fff;
  background: #5db461;
  border-radius: 20px;
}
</style>
