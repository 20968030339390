<template>
  <div>
    <div
      id="search-station"
      class="search_wrap"
      :class="{ active: searchFieldVisibility }"
      @click="searchFieldVisibility = true">
      <div class="form">
        <input
          id="form_input" type="text"
          autocomplete="off"
          v-model="searchedValue"
          @input="onInput"
          ref="input"
          :placeholder="$t('search.search_field_placeholder')"
        />
        <button type="button">
          <img src="../../../assets/img/search.svg" alt="">
          <span class="text">{{ $t('search.title') }}</span>
        </button>
      </div>
      <div class="result_stations">
        <ul ref="scroll" @scroll="onScroll">
          <li
            v-for="(station, i) in stations"
            @click.stop="selectStation(station)"
            :key="i">
            <div class="img" v-html="marker(station)"></div>
            <span>{{ station.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/eventBus'
import { getMarkerData } from '@/helpers/connectors'
import { SHOW_STATION_INFO_EVENT } from '@/constants'
import { GET_SEARCHED_STATIONS_ACTION } from '@/store/constants'

export default {
  name: 'SearchStation',
  data: () => ({
    searchedValue: '',
    timeout: null,
    stations: [],
    offset: 0,
    limit: 25,
    searchFieldVisibility: false
  }),
  mounted () {
    document.addEventListener('click', (e) => {
      let el = e.target

      while (el) {
        if (el.id === 'search-station') {
          return true
        }
        el = el.parentNode
      }

      this.hide()
    })
  },
  methods: {
    async onScroll () {
      const { scroll } = this.$refs

      if (scroll.scrollTop + scroll.offsetHeight === scroll.scrollHeight) {
        this.offset = this.stations.length

        const newStations = await this.getStations()
        this.stations = [...this.stations, ...newStations]
      }
    },
    onInput () {
      if (this.searchedValue.length > 3) {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(async () => {
          this.stations = await this.getStations() || []
          this.offset = 0
        }, 1000)
      }
    },
    async getStations () {
      return await this.$store.dispatch(GET_SEARCHED_STATIONS_ACTION, {
        name: this.searchedValue,
        offset: this.offset,
        limit: this.limit
      })
    },
    selectStation (station) {
      eventBus.$emit(SHOW_STATION_INFO_EVENT, station)
      this.hide()
    },
    marker (station) {
      const { connectorImg } = getMarkerData(station.conn)
      return connectorImg
    },
    hide () {
      this.searchFieldVisibility = false
      this.stations = []
      this.searchedValue = ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/includes/mixin_scroll_reset";
.search{
  &_wrap{
    position: fixed;
    width: 443px;
    top: 20%;
    left: -375px;
    z-index: 2;
    transition: all 200ms ease-in-out;
    &.active {
      left: 41px;
      @media (max-width: 575px) {
        left: 0;
        width: 320px;
      }

      .form button {
        right: 15px;
        top: 50%;
        img {
          width: 24px;
        }
        .text {
          display: none;
        }
      }
      .result_stations{
        transform: translate(0, 0);
      }
    }

    .form{
      position: relative;
      width: 100%;
      display: block;
      input{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 12px 15px;
        height: 65px;
        line-height: 65px;
        text-align: left;
        color: #4F4F4F;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        @media (max-width: 575px) {
          padding-right: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        ::placeholder{
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #828282;
        }
      }
      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
        border-radius: 0;
        padding: 0;
        border: 0;
        position: absolute;
        right: 10px;
        top: 53%;
        transform: translate(0, -50%);
        color: #333333;
        img {
          width: 21px;
        }
      }
    }
    .result_stations{
      width: 100%;
      height: auto;
      transform: translate(-100%, 0);
      ul{
        @include scrollReset(none, transparent);
        border-radius: 10px;
        overflow: auto;
        transform: translate(0, 2px);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        max-height: 389px;
        padding: 0;
        margin-top: 0;
        li{
          position: relative;
          padding: 8px 15px;
          height: 65px;
          line-height: 65px;
          display: flex;
          background: #fff;
          align-items: center;
          &:after{
            content: "";
            position: absolute;
            height: 1px;
            background: #ccc;
            width: 95%;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 0);
          }
          &:hover {
            cursor: pointer;
          }
          .img{
            display: flex;
            margin-right: 15px
          }
          img{
            width: 100%;
          }
          span{
            width: calc(100% - 35px);
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #4F4F4F;
            line-height: 1.5;
          }
        }
      }
    }
  }
}

</style>
