<template>
  <div class="home-page">
    <TheMap />
    <StationFilter />
    <StationInfo :station="selectedStation" v-if="selectedStation" />
    <EditStationInfo v-if="newStation" />
    <AuthPopup />
    <ChooseConnectorModal v-if="modals.chooseConnectorModalVisibility" />
    <ChooseNearbyPlacesModal v-if="modals.nearbyPlacesModalVisibility" />
    <ScheduleModal v-if="modals.scheduleModalVisibility" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TheMap from '@/components/pages/home/Map'
import StationFilter from '@/components/pages/home/StationFilter'
import StationInfo from '@/components/pages/home/station-info/StationInfo'
import AuthPopup from '@/components/pages/home/AuthPopup'
import ChooseConnectorModal from '@/components/pages/home/modals/ChooseConnectorModal'
import ChooseNearbyPlacesModal from '@/components/pages/home/modals/ChooseNearbyPlacesModal'
import ScheduleModal from '@/components/pages/home/modals/schedule-modal/ScheduleModal'
import EditStationInfo from '@/components/pages/home/edit-station-info/EditStationInfo'

export default {
  name: 'HomePage',
  components: {
    TheMap,
    StationFilter,
    StationInfo,
    ChooseNearbyPlacesModal,
    ChooseConnectorModal,
    ScheduleModal,
    AuthPopup,
    EditStationInfo
  },
  computed: {
    ...mapState([
      'stations',
      'authMenuVisibility',
      'selectedStation',
      'modals',
      'newStation'
    ])
  }
}
</script>

<style scoped lang="scss">
  .home-page {
    height: 100%;
  }
</style>
