import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vue2TouchEvents from 'vue2-touch-events'
import VueResource from 'vue-resource'
import App from './App.vue'
import messages from './i18n'
import store from './store'
import './directives/dropdown'
import router from './router'
import './assets/scss/style.scss'
// import dbInit from './db/init'

Vue.use(VueResource)
Vue.use(VueI18n)
Vue.use(Vue2TouchEvents)

Vue.http.options.root = `${process.env.VUE_APP_CHARGE_API_URL}/api`

// dbInit.then((connection) => {
//   console.log(connection)
// }).catch((err) => {
//   console.err(`Cant connect to db ${err.message}`)
// })

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: localStorage.locale || 'ru',
  fallbackLocale: 'en',
  messages
})

new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
