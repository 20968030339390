import { connectorTypes } from '@/data/connectorTypes'
export * from '@/data/connectorTypes'
export * from '@/data/connectorStatuses'

export const connectorTypeColors = {
  fast: '#FFBD4F',
  regular: '#31B447',
  unknown: '#A0A3A4'
}

export const currencySymbols = {
  RUB: '₽',
  EUR: '€',
  USD: '$',
  UAH: '₴',
  GBP: '£'
}

export const connectorTypesInFilter = [
  {
    key: 1,
    name: connectorTypes[5].name,
    img: connectorTypes[5].imgName,
    connectorTypes: [5],
    kWh: '0',
    sessions: '0',
    fast: false,
    count: 0
  },
  {
    key: 2,
    name: connectorTypes[8].name,
    img: connectorTypes[8].imgName,
    connectorTypes: [6, 7, 8, 9],
    fast: false,
    kWh: '0',
    sessions: '0',
    count: 0
  },
  {
    key: 3,
    name: connectorTypes[1].name,
    img: connectorTypes[1].imgName,
    connectorTypes: [1],
    fast: true,
    kWh: '0',
    sessions: '0',
    count: 0
  },
  {
    key: 4,
    name: connectorTypes[4].name,
    img: connectorTypes[4].imgName,
    connectorTypes: [2, 3, 4],
    fast: true,
    kWh: '0',
    sessions: '0',
    count: 0
  }
]
