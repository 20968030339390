import { languages } from '@/data/languages'

export default {
  data: () => ({
    languages: { ...languages }
  }),
  computed: {
    locale () {
      return this.$i18n.locale
    },
    filteredList () {
      const result = {}

      for (const key in this.languages) {
        if (key !== this.locale) {
          result[key] = this.languages[key]
        }
      }

      return result
    }
  },
  methods: {
    changeLocale (lang) {
      if (this.locale === lang) {
        return
      }
      localStorage.locale = lang
      location.reload()
    },
    getPathToLangIcon (name) {
      return `/v2/flags/${name}.svg`
    }
  }
}
