import {
  SET_AUTH_MENU_VISIBILITY_MUTATION,
  SET_MENU_VISIBILITY_MUTATION,
  SET_MODAL_VISIBILITY_MUTATION,
  SET_NEW_STATION_MUTATION,
  SET_SELECTED_STATION_MUTATION,
  SET_STATION_MUTATION,
  SET_STATIONS_MUTATION
} from '@/store/constants'

export default {
  [SET_STATIONS_MUTATION] (state, stations) {
    state.stations = stations
  },
  [SET_SELECTED_STATION_MUTATION] (state, station) {
    state.selectedStation = station
  },
  [SET_MENU_VISIBILITY_MUTATION] (state, flag) {
    state.menuVisibility = flag
  },
  [SET_AUTH_MENU_VISIBILITY_MUTATION] (state, flag) {
    state.authMenuVisibility = flag
  },
  [SET_STATION_MUTATION] (state, station) {
    state.stations[station._id] = station
  },
  [SET_MODAL_VISIBILITY_MUTATION] (state, { modalName, flag }) {
    state.modals[modalName] = flag
  },
  [SET_NEW_STATION_MUTATION] (state, station) {
    state.newStation = station
  }
}
