<template>
  <ModalWrapper :show-backdrop="menuVisibility" @closeModal="closeModal">
    <div class="burger_menu_mobile" :class="(menuVisibility) ? 'opened' : ''">
<!--      <div class="profile_block block_item">-->
<!--        <ul>-->
<!--          <li class="non_active menu_title">{{ $t('profile.profile_title')}}</li>-->
<!--          <li class="child_li"><span>{{ $t('profile.hello_word')}}</span><span class="user_name">Dan</span></li>-->
<!--          <li class="child_li"><span>{{ $t('profile.balance')}}</span><span class="user_balance">0.00$</span></li>-->
<!--        </ul>-->
<!--      </div>-->
      <div class="language_block block_item">
        <ul>
          <li class="non_active menu_title">{{ $t('language.name')}}</li>
          <li class="child_li lang language_choosen_mobile"  :class="{ active: activeLanguageListMobile }" @click="openLanguageListMobile">
            <div class="lang_choosen">
              <img :src="getPathToLangIcon(languages[locale].iconName)"
                   :alt="languages[locale].name">
              <span>{{ languages[locale].name }}
              <img src="../assets/img/down_arrow.svg" alt="">
            </span>
            </div>
            <ul class="language_list_mobile">
              <li class="language_list_mobile_item"
                  v-for="(lang, key) in filteredList"
                  @click="changeLocale(key)"
                  :key="key">
                <img :src="getPathToLangIcon(lang.iconName)" :alt="lang.name">
                <span>{{ lang.name }}</span>
              </li>
            </ul>
          </li>
        </ul>
        </div>
<!--      <div class="menu_block block_item">-->
<!--        <ul>-->
<!--          <li class="non_active menu_title">{{ $t('main_menu.title') }}</li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.main_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.charge_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.cars_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.statistic_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.sales_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.my_stations_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.comments_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.new_stations_page')}}</a></li>-->
<!--          <li class="child_li"><a href="">{{$t('main_menu.control_room_page')}}</a></li>-->
<!--        </ul>-->
<!--      </div>-->
      <div class="info_block block_item">
        <ul>
          <li class="child_li"><a href="https://autoenterprise.ua/pages/about">{{ $t('header.about_company') }}</a></li>
          <li class="child_li">
            <a href="https://autoenterprise.ua">
              {{ $t('header.about_service') }}
            </a>
          </li>
        </ul>
      </div>

      <div class="charge_wrapper">
        <a href="#" class="buy_charge_station">
          {{ $t('header.buy_station') }}
        </a>
        <div class="connectors_types">
          <div class="connectors_types_item">
            <img src="/v2/connectors_and_sockets/j-1772-01.svg" alt="T1">
          </div>
          <div class="connectors_types_item">
            <img src="/v2/connectors_and_sockets/type2_socket2-01.svg" alt="T1">
          </div>
          <div class="connectors_types_item">
            <img src="/v2/connectors_and_sockets/type2_soket2-02-01.svg" alt="T1">
          </div>
          <div class="connectors_types_item">
            <img src="/v2/connectors_and_sockets/chademo.svg" alt="T1">
          </div>
          <div class="connectors_types_item">
            <img src="/v2/connectors_and_sockets/ccs_type1.svg" alt="T1">
          </div>
          <div class="connectors_types_item">
            <img src="/v2/connectors_and_sockets/ccs_type2.svg" alt="T1">
          </div>
        </div>

      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapState } from 'vuex'
import langMixin from '@/mixins/langMixin'
import { SET_MENU_VISIBILITY_MUTATION } from '@/store/constants'
import ModalWrapper from '@/components/ModalWrapper'

export default {
  name: 'MenuPopup',
  mixins: [
    langMixin
  ],
  components: {
    ModalWrapper
  },
  data: () => ({
    activeLanguageListMobile: false
  }),
  computed: {
    ...mapState(['menuVisibility'])
  },
  methods: {
    openLanguageListMobile () {
      this.activeLanguageListMobile = !this.activeLanguageListMobile
    },
    closeModal () {
      this.$store.commit(SET_MENU_VISIBILITY_MUTATION, false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/includes/mixin_scroll_reset";
.burger_menu_mobile {
  @include scrollReset(none, transparent);
  @media (max-width: 1250px) {
  }
}
.charge_wrapper {
  padding: 0 30px;
  display: none;
  @media (max-width: 1250px) {
    display: block;
  }
}
.menu_block {
  li.child_li {
    a {
      font-weight: 700;
    }
  }
}
.block_item {
  border-bottom: 1px solid #BDBDBD;
  &.info_block {
    border-bottom: 0;
  }
  li {
    &.child_li {
      padding-left: 40px;
    }
    a {
      &:hover {
        color: #5AB464;
      }
    }
    span {
      font-size: 14px;
      &.user_balance,
      &.user_name {
        color: #5AB464;
      }
    }
  }
}
.buy_charge_station {
  border: 1px solid #5AB464;
  width: 100%;
  padding: 5px 7px;
  color: #5AB464;
  margin-bottom: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;

  &:hover {
    color: #fff;
    background-color: #5AB464;
  }
}
.connectors_types {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_item {
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.language_list_mobile {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  &_item {
    height: auto;
    padding: 5px 20px !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      span {
        color: #5AB464;
      }
    }
    img {
      width: 25px;
      height: 25px;
      border-radius: 50px;
      object-fit: cover;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
      margin: 0 !important;
    }
    span {
      padding-left: 10px;
      transition: all 0.3s ease-in-out;
    }
  }
}
.language_choosen_mobile {
  cursor: pointer;
  .lang_choosen {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      img {
        width: 25px;
        height: 15px;
        box-shadow: none;
        border-radius: 0;
        padding: 0 5px;
      }
    }
  }
  &.active {
    .lang_choosen span img {
      transform: rotate(-180deg);
    }
    .language_list_mobile {
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    }
  }
  img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    object-fit: cover;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    margin: 0 !important;
  }
  span {
    padding-left: 10px;
    position: relative;
  }
}

</style>
