export default {
  header: {
    login: 'Login',
    about_company: 'O firmie',
    about_service: 'O usłudze',
    buy_station: 'Kup stację ładującą'
  },
  auth_popup: {
    authorization: 'Autoryzacja',
    reset_password_label: 'Resetuj hasło',
    reset_password: 'Resetuj hasło',
    register: 'Register',
    registration: 'Rejestracja',
    our_application: 'Nasza aplikacja',
    agree_to_terms: 'Zgadzam się z <a target="_blank" href="{url}" class="rools_link"> warunkami </a>',
    form: {
      login: 'E-mail lub numer telefonu',
      email: 'E-mail',
      password: 'Hasło',
      repeat_password: 'Powtórz hasło',
      name: 'Name',
      second_name: 'Drugie imię',
      phone_number: 'Numer telefonu',
      vin: 'VIN',
      plate: 'Plate',
      auto_vendor: 'Auto vendor',
      auto_model: 'Auto model',
      secret_word: 'Secret word'
    }
  },
  language: {
    name: 'Język'
  },
  profile: {
    profile_title: 'Profil',
    hello_word: 'Witamy, ',
    balance: 'Saldo: '
  },
  main_menu: {
    title: 'Menu',
    main_page: 'Strona główna',
    charge_page: 'Ładowanie',
    cars_page: 'Moje samochody',
    statistic_page: 'Statystyki',
    sales_page: 'Sprzedaż',
    my_stations_page: 'Moje stacje',
    comments_page: 'Komentarze',
    new_stations_page: 'Nowe stacje',
    control_room_page: 'Pokój kontrolny'
  },
  mobile_modal: {
    app_is_better: 'Aplikacja jest wygodniejsza',
    work_from_phone: 'Pobierz i pracuj na swoim telefonie!'
  },
  station_sidebar: {
    go_to_site: 'Idź do serwisu',
    open: 'Otwórz',
    near: 'Blisko',
    schedule: {
      all_day: '24/7'
    },
    comments: {
      month_ago: 'Miesiąc temu',
      year_ago: 'rok temu',
      years_ago: 'lata temu',
      'two-four_months_ago': 'miesiące temu',
      'five-twelve_months_ago': 'miesiące temu',
      today: 'dzisiaj',
      days_ago: 'dni temu',
      cancel_button: 'Anuluj',
      send_button: 'Wysłać'
    },
    rates: {
      time: 'h',
      parking: 'min',
      kWh: 'kW*h'
    }
  },
  filters: {
    kW: 'kW',
    sessions: 'Sesje',
    charged: 'Naładowano'
  },
  search: {
    title: 'Szukaj',
    search_field_placeholder: 'Wprowadź nazwę stacji'
  },
  statuses: {
    available: 'Dostępny',
    preparing: 'Ładowanie',
    charging: 'Ładowanie',
    finishing: 'Ładowanie',
    suspendedEVSE: 'Ładowanie',
    suspendedEV: 'Ładowanie',
    occupied: 'Ładowanie',
    unavailable: 'Niedostępny',
    faulted: 'Uszkodzony',
    unknown: 'Unknown'
  },
  edit_station_info: {
    edit_station_name: 'Nazwa stacji',
    edit_station_connectors_name: 'Łączniki',
    edit_address_station: 'Adres stacji',
    edit_station_description: 'Opis',
    edit_site_station: 'Site stacji (firma)',
    edit_button: 'Zredagować',
    incorrect_name: 'Błędna nazwa',
    incorrect_address: 'Niepoprawny adres',
    edit_photo: 'Zdjęcia',
    save_button: 'Zapisz'
  },
  edit_stations_images: {
    edit_photo: 'Dodaj zdjęcie'
  },
  edit_station_connectors: {
    edit_connector: 'Dodaj łącznik'
  }
}
