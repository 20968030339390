<template>
  <ModalWrapper :show-backdrop="authMenuVisibility" @closeModal="close">
    <div class="authorization_popup" :class="(authMenuVisibility) ? 'active' : ''">
      <form id="login_form" action="/" method="post" class="login_form" :class="(action === 'login') ? 'active' : ''">
        <h3>{{ $t('auth_popup.authorization') }}</h3>
        <label>
          <input type="text" name="email" :placeholder="$t('auth_popup.form.login')" required/>
        </label>
        <label class="label">
          <input :type="(showPassword) ? 'text' : 'password'" :placeholder="$t('auth_popup.form.password')" name="password" class="password" required/>
          <span class="toggle_password" @click.stop="showPassword = !showPassword">
              <img src="../../../assets/img/eye.svg" v-show="!showPassword">
              <img src="../../../assets/img/cross-eye.svg" v-show="showPassword">
        </span>
        </label>
        <div class="actions">
          <a href="/" class="color_black reset_btn_js" @click.prevent="action = 'resetPassword'">
            {{ $t('auth_popup.reset_password_label') }}
          </a>
          <button type="submit">
            {{ $t('header.login') }}
          </button>
          <a href="/" class="color_green registration_btn_js" @click.prevent="action = 'register'">
            {{ $t('auth_popup.registration') }}
          </a>
        </div>
      </form>

      <form id="reset_form" action="/" method="post" class="reset_form" :class="(action === 'resetPassword') ? 'active' : ''">
        <h3>
          {{ $t('auth_popup.reset_password_label') }}
        </h3>
        <label>
          <input type="email" name="email_recovery" :placeholder="$t('auth_popup.form.email')" required/>
        </label>
        <div class="actions">
          <button type="submit">
            {{ $t('auth_popup.reset_password') }}
          </button>
        </div>
        <div class="back back_js" @click="action = 'login'">
          <img src="../../../assets/img/angle_left.svg" alt="back"/>
        </div>
      </form>

      <form id="registration_form" action="/" method="post" class="registration_form" :class="(action === 'register') ? 'active' : ''">
        <h3>
          {{ $t('auth_popup.registration') }}
        </h3>
        <label>
          <input type="text" name="name_register" :placeholder="$t('auth_popup.form.name')" required/>
        </label>
        <label>
          <input type="email" name="email_register" :placeholder="$t('auth_popup.form.email')" required/>
        </label>
        <label>
          <input type="tel" name="phone_register" :placeholder="$t('auth_popup.form.phone_number')" required/>
        </label>
        <label>
          <input type="text" name="vin_register" :placeholder="$t('auth_popup.form.phone_number')" required/>
        </label>
        <label>
          <input type="text" name="plate_register" :placeholder="$t('auth_popup.form.phone_number')" required/>
        </label>
        <label>
          <input type="text" name="auto_vendor_register" :placeholder="$t('auth_popup.form.phone_number')" required/>
        </label>
        <label>
          <input type="text" name="auto_model_register" :placeholder="$t('auth_popup.form.phone_number')" required/>
        </label>
        <label>
          <input type="text" name="secret_word_register" :placeholder="$t('auth_popup.form.phone_number')" required/>
        </label>
        <label>
          <input type="checkbox" name="agree_register" value="agree" required/>
          <span v-html="$t('auth_popup.agree_to_terms', { url: 'https://charge.autoenterprise.com.ua/docs/legal_uk.pdf' })"></span>
        </label>
        <label>
          <div class="g-recaptcha" data-sitekey="6LfcqBYUAAAAAMIP_QNlE5-ir-e2B5e8S6g_scMs"></div>
          <script src='https://www.google.com/recaptcha/api.js'></script>
        </label>
        <div class="actions">
          <button type="submit">
            {{ $t('auth_popup.register') }}
          </button>
        </div>
        <div class="back back_js" @click="action = 'login'">
          <img src="../../../assets/img/angle_left.svg" alt="back"/>
        </div>
      </form>
      <div class="apps">
        <h4>{{ $t('auth_popup.our_application') }}</h4>
        <ul class="app_icons">
          <li>
            <a href="https://apps.apple.com/ua/app/ae-charging-point/id1274189210?l=ru" target="_blank">
              <img src="../../../assets/img/app_market.svg" alt="app ios" />
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=ua.com.autoenterprise.charge.chargeae&hl=ru&gl=US" target="_blank">
              <img src="../../../assets/img/google_play.svg" alt="app google play" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapState } from 'vuex'
import { popupMixin } from '@/mixins/popupMixin'
import { TOGGLE_AUTH_MENU_ACTION } from '@/store/constants'

export default {
  name: 'AuthPopup',
  mixins: [
    popupMixin
  ],
  data: () => ({
    action: 'login',
    showPassword: false
  }),
  computed: {
    ...mapState([
      'authMenuVisibility'
    ])
  },
  methods: {
    close () {
      this.$store.dispatch(TOGGLE_AUTH_MENU_ACTION)
    }
  }
}
</script>

<style scoped lang="scss">
.authorization_popup.active {
  z-index: 4;
}
.app_icons{
  padding: 20px 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.apps {
  padding: 0 20px 10px 20px;
  text-align: center;

  h4 {
    border-top: 1px solid #E0E0E0;
    padding-top: 20px;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 0;
      width: calc(100% - 40px);
      left: 20px;
      height: 1px;
      background-color: #E0E0E0;
    }

    ul {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      li:first-child {
        margin-right: 20px;
      }
    }
  }
}
.authorization_popup {
  top: 61px;
}
.registration_form {
  label {
    display: flex;
    align-items: center;
    input[type="checkbox"] {
      margin-left: 16px;
      width: auto;
      margin-right: 5px;
    }
    a.rools_link {
      color: #5DB461;
    }
  }
}
@media all and (min-width: 1251px) {
  .authorization_popup {
    top: 86px;
  }
}
</style>
