export default {
  1: `<svg *svgParams*>
    <g class="g_marker">
        <path d="M6,12.6L6,12.6L6,12.6c0-3.2,1.3-6.2,3.5-8.4c2.2-2.2,5.3-3.5,8.5-3.5l0,0h0h0c3.2,0,6.2,1.3,8.5,3.5   c2.2,2.2,3.5,5.3,3.5,8.4v0c0,2.2-0.7,4.8-1.9,7.4c-1.2,2.6-2.7,5.2-4.3,7.5c-1.6,2.3-3.1,4.3-4.3,5.8c-0.6,0.7-1.1,1.3-1.4,1.7   c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.4-0.8-1-1.4-1.7c-1.2-1.4-2.8-3.5-4.3-5.8c-1.6-2.3-3.1-4.9-4.3-7.5C6.8,17.4,6,14.8,6,12.6z"
              fill="#fff"></path>
        <path d="M18,36l-0.5-0.6c-0.4-0.4-0.9-1-1.5-1.7c-1.1-1.3-2.7-3.4-4.4-5.8c-1.8-2.7-3.3-5.3-4.4-7.6   c-1.3-2.9-2-5.5-2-7.6v0C5.3,9.2,6.6,6,9,3.7C11.4,1.3,14.6,0,18,0c3.4,0,6.6,1.3,9,3.7c2.4,2.4,3.7,5.5,3.7,8.9   c0,2.2-0.7,4.7-2,7.6c-1.1,2.3-2.5,4.9-4.4,7.6c-1.6,2.4-3.3,4.5-4.4,5.8c-0.6,0.7-1.1,1.3-1.4,1.7L18,36z M6.7,12.6   c0,2,0.6,4.3,1.9,7.1c1,2.3,2.4,4.7,4.2,7.4c1.6,2.4,3.2,4.4,4.3,5.7c0.3,0.4,0.7,0.8,0.9,1.1c0.3-0.3,0.6-0.7,0.9-1.1   c1.1-1.3,2.7-3.4,4.3-5.7c1.8-2.6,3.2-5.1,4.2-7.4c1.2-2.7,1.9-5.1,1.9-7.1c0-3-1.2-5.8-3.3-7.9c-2.1-2.1-5-3.3-8-3.3c0,0,0,0,0,0   c-3,0-5.9,1.2-8,3.3C7.9,6.8,6.7,9.6,6.7,12.6L6.7,12.6z"
              fill="#31B447"></path>
    </g>
    <g class="g_types">
        <path d="M23.2,21.8c5-2.9,6.7-9.3,3.8-14.3c-2.9-5-9.3-6.7-14.3-3.8C7.8,6.6,6.1,13,9,18   S18.2,24.7,23.2,21.8z M13.8,15.2c-1.3-2.3-0.5-5.3,1.8-6.7c2.3-1.3,5.3-0.5,6.7,1.8s0.5,5.3-1.8,6.7S15.1,17.5,13.8,15.2z"
              *fill1*></path>
    </g>
    <g class="g_statuses">
        <path d="M22.6,4.8c-4.4-2.5-10-1-12.5,3.3c-2.5,4.4-1,10,3.3,12.5c4.4,2.5,10,1,12.5-3.3   S26.9,7.4,22.6,4.8z M23.5,16c-1.8,3.1-5.7,4.1-8.7,2.3c-3.1-1.8-4.1-5.7-2.3-8.7s5.7-4.1,8.7-2.3S25.3,12.9,23.5,16z"
        *stroke1*></path>
    </g>
</svg>
  `,
  2: `<svg *svgParams*>
    <g class="g_marker">
        <path d="M6,12.6L6,12.6L6,12.6c0-3.2,1.3-6.2,3.5-8.4c2.2-2.2,5.3-3.5,8.5-3.5l0,0h0h0c3.2,0,6.2,1.3,8.5,3.5   c2.2,2.2,3.5,5.3,3.5,8.4v0c0,2.2-0.7,4.8-1.9,7.4c-1.2,2.6-2.7,5.2-4.3,7.5c-1.6,2.3-3.1,4.3-4.3,5.8c-0.6,0.7-1.1,1.3-1.4,1.7   c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.4-0.8-1-1.4-1.7c-1.2-1.4-2.8-3.5-4.3-5.8c-1.6-2.3-3.1-4.9-4.3-7.5C6.8,17.4,6,14.8,6,12.6z"
              fill="#fff"></path>
        <path d="M18,36l-0.5-0.6c-0.4-0.4-0.9-1-1.5-1.7c-1.1-1.3-2.7-3.4-4.4-5.8c-1.8-2.7-3.3-5.3-4.4-7.6   c-1.3-2.9-2-5.5-2-7.6v0C5.3,9.2,6.6,6,9,3.7C11.4,1.3,14.6,0,18,0c3.4,0,6.6,1.3,9,3.7c2.4,2.4,3.7,5.5,3.7,8.9   c0,2.2-0.7,4.7-2,7.6c-1.1,2.3-2.5,4.9-4.4,7.6c-1.6,2.4-3.3,4.5-4.4,5.8c-0.6,0.7-1.1,1.3-1.4,1.7L18,36z M6.7,12.6   c0,2,0.6,4.3,1.9,7.1c1,2.3,2.4,4.7,4.2,7.4c1.6,2.4,3.2,4.4,4.3,5.7c0.3,0.4,0.7,0.8,0.9,1.1c0.3-0.3,0.6-0.7,0.9-1.1   c1.1-1.3,2.7-3.4,4.3-5.7c1.8-2.6,3.2-5.1,4.2-7.4c1.2-2.7,1.9-5.1,1.9-7.1c0-3-1.2-5.8-3.3-7.9c-2.1-2.1-5-3.3-8-3.3c0,0,0,0,0,0   c-3,0-5.9,1.2-8,3.3C7.9,6.8,6.7,9.6,6.7,12.6L6.7,12.6z"
              fill="#31B447"></path>
    </g>
    <g class="g_types">
        <path d="M17.3,17.6c-1.4-0.2-2.7-1-3.5-2.4c-1.3-2.3-0.5-5.3,1.8-6.7c0.5-0.3,1.1-0.5,1.7-0.6V2.4   c-1.5,0.1-3.1,0.5-4.5,1.4C7.8,6.6,6.1,13,9,18c1.8,3.1,5,5,8.3,5.2V17.6z"
              *fill2*></path>
        <path d="M27,7.5c-1.8-3.1-5-5-8.3-5.2V8c1.4,0.2,2.7,1,3.5,2.4c1.3,2.3,0.5,5.3-1.8,6.7   c-0.5,0.3-1.1,0.5-1.7,0.6v5.6c1.5-0.1,3.1-0.5,4.5-1.4C28.2,18.9,29.9,12.5,27,7.5z"
        *fill1*></path>
    </g>
    <g class="g_statuses">
        <path d="M16,18.8c-0.4-0.1-0.8-0.3-1.2-0.5c-3.1-1.8-4.1-5.7-2.3-8.7c0.8-1.4,2.1-2.4,3.5-2.9V3.8   c-2.4,0.5-4.6,2-5.9,4.3c-2.5,4.4-1,10,3.3,12.5c0.8,0.5,1.7,0.8,2.6,1V18.8z"
              *stroke2*></path>
        <path d="M22.6,4.8c-0.8-0.5-1.7-0.8-2.6-1v2.9c0.4,0.1,0.8,0.3,1.2,0.5c3.1,1.8,4.1,5.7,2.3,8.7   c-0.8,1.4-2.1,2.4-3.5,2.9v2.8c2.4-0.5,4.6-2,5.9-4.3C28.4,13,26.9,7.4,22.6,4.8z"
        *stroke1*></path>
    </g>
</svg>`,
  3: `<svg *svgParams*>
    <g class="g_marker">
        <path d="M6,12.6L6,12.6L6,12.6c0-3.2,1.3-6.2,3.5-8.4c2.2-2.2,5.3-3.5,8.5-3.5l0,0h0h0c3.2,0,6.2,1.3,8.5,3.5   c2.2,2.2,3.5,5.3,3.5,8.4v0c0,2.2-0.7,4.8-1.9,7.4c-1.2,2.6-2.7,5.2-4.3,7.5c-1.6,2.3-3.1,4.3-4.3,5.8c-0.6,0.7-1.1,1.3-1.4,1.7   c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.4-0.8-1-1.4-1.7c-1.2-1.4-2.8-3.5-4.3-5.8c-1.6-2.3-3.1-4.9-4.3-7.5C6.8,17.4,6,14.8,6,12.6z"
              fill="#fff"></path>
        <path d="M18,36l-0.5-0.6c-0.4-0.4-0.9-1-1.5-1.7c-1.1-1.3-2.7-3.4-4.4-5.8c-1.8-2.7-3.3-5.3-4.4-7.6   c-1.3-2.9-2-5.5-2-7.6v0C5.3,9.2,6.6,6,9,3.7C11.4,1.3,14.6,0,18,0c3.4,0,6.6,1.3,9,3.7c2.4,2.4,3.7,5.5,3.7,8.9   c0,2.2-0.7,4.7-2,7.6c-1.1,2.3-2.5,4.9-4.4,7.6c-1.6,2.4-3.3,4.5-4.4,5.8c-0.6,0.7-1.1,1.3-1.4,1.7L18,36z M6.7,12.6   c0,2,0.6,4.3,1.9,7.1c1,2.3,2.4,4.7,4.2,7.4c1.6,2.4,3.2,4.4,4.3,5.7c0.3,0.4,0.7,0.8,0.9,1.1c0.3-0.3,0.6-0.7,0.9-1.1   c1.1-1.3,2.7-3.4,4.3-5.7c1.8-2.6,3.2-5.1,4.2-7.4c1.2-2.7,1.9-5.1,1.9-7.1c0-3-1.2-5.8-3.3-7.9c-2.1-2.1-5-3.3-8-3.3c0,0,0,0,0,0   c-3,0-5.9,1.2-8,3.3C7.9,6.8,6.7,9.6,6.7,12.6L6.7,12.6z"
              fill="#31B447"></path>
    </g>
    <g class="g_types">
        <path d="M13.5,14.5c-0.9-2.2,0-4.8,2.1-6c0.5-0.3,1.1-0.5,1.7-0.6V2.4c-1.5,0.1-3.1,0.5-4.5,1.4   C8,6.5,6.2,12.4,8.6,17.3L13.5,14.5z"
              *fill3*></path>
        <path d="M18.7,8c1.4,0.2,2.7,1,3.5,2.4c0.8,1.3,0.8,2.9,0.3,4.2l4.9,2.8c1.5-3,1.5-6.7-0.3-9.8s-5-5-8.3-5.2V8z"
    *fill1*></path>
        <path d="M21.8,15.8c-0.4,0.5-0.8,0.9-1.4,1.2c-2.1,1.2-4.8,0.7-6.2-1.2l-4.8,2.8c3,4.5,9.1,6,13.9,3.2   c1.4-0.8,2.6-1.9,3.4-3.2L21.8,15.8z"
        *fill2*></path>
    </g>
    <g class="g_statuses">
        <path d="M11.7,14.1c-0.3-1.5-0.1-3.1,0.7-4.5c0.8-1.4,2.1-2.4,3.5-2.9V3.8c-2.4,0.5-4.6,2-5.9,4.3   c-1.3,2.3-1.5,4.9-0.8,7.3L11.7,14.1z"
        *stroke3*></path>
        <path d="M20,6.7c0.4,0.1,0.8,0.3,1.2,0.5c2.5,1.4,3.6,4.2,3.1,6.8l2.5,1.4c1.3-4-0.4-8.5-4.2-10.7   c-0.8-0.5-1.7-0.8-2.6-1V6.7z"
        *stroke1*></path>
        <path d="M22.3,17.5c-2,1.8-5,2.2-7.5,0.8c-0.4-0.2-0.7-0.5-1.1-0.8L11.3,19c0.6,0.7,1.3,1.3,2.2,1.7   c3.8,2.2,8.5,1.4,11.3-1.7L22.3,17.5z"
        *stroke2*></path>
    </g>
</svg>
`,
  4: `
  <svg *svgParams*>
    <g class="g_marker">
        <path d="M6,12.6L6,12.6L6,12.6c0-3.2,1.3-6.2,3.5-8.4c2.2-2.2,5.3-3.5,8.5-3.5l0,0h0h0c3.2,0,6.2,1.3,8.5,3.5   c2.2,2.2,3.5,5.3,3.5,8.4v0c0,2.2-0.7,4.8-1.9,7.4c-1.2,2.6-2.7,5.2-4.3,7.5c-1.6,2.3-3.1,4.3-4.3,5.8c-0.6,0.7-1.1,1.3-1.4,1.7   c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.4-0.8-1-1.4-1.7c-1.2-1.4-2.8-3.5-4.3-5.8c-1.6-2.3-3.1-4.9-4.3-7.5C6.8,17.4,6,14.8,6,12.6z"
              fill="#fff"></path>
        <path d="M18,36l-0.5-0.6c-0.4-0.4-0.9-1-1.5-1.7c-1.1-1.3-2.7-3.4-4.4-5.8c-1.8-2.7-3.3-5.3-4.4-7.6   c-1.3-2.9-2-5.5-2-7.6v0C5.3,9.2,6.6,6,9,3.7C11.4,1.3,14.6,0,18,0c3.4,0,6.6,1.3,9,3.7c2.4,2.4,3.7,5.5,3.7,8.9   c0,2.2-0.7,4.7-2,7.6c-1.1,2.3-2.5,4.9-4.4,7.6c-1.6,2.4-3.3,4.5-4.4,5.8c-0.6,0.7-1.1,1.3-1.4,1.7L18,36z M6.7,12.6   c0,2,0.6,4.3,1.9,7.1c1,2.3,2.4,4.7,4.2,7.4c1.6,2.4,3.2,4.4,4.3,5.7c0.3,0.4,0.7,0.8,0.9,1.1c0.3-0.3,0.6-0.7,0.9-1.1   c1.1-1.3,2.7-3.4,4.3-5.7c1.8-2.6,3.2-5.1,4.2-7.4c1.2-2.7,1.9-5.1,1.9-7.1c0-3-1.2-5.8-3.3-7.9c-2.1-2.1-5-3.3-8-3.3c0,0,0,0,0,0   c-3,0-5.9,1.2-8,3.3C7.9,6.8,6.7,9.6,6.7,12.6L6.7,12.6z"
              fill="#31B447"></path>
    </g>
    <g class="g_types">
        <path d="M13.2,12c0.2-1.4,1-2.7,2.4-3.5c0.5-0.3,1.1-0.5,1.7-0.6V2.4c-1.5,0.1-3.1,0.5-4.5,1.4   c-3.1,1.8-5,5-5.2,8.3H13.2z"
        *fill4*></path>
        <path d="M22.2,10.3c0.3,0.5,0.5,1.1,0.6,1.7h5.6C28.3,10.5,27.9,9,27,7.5c-1.8-3.1-5-5-8.3-5.2V8   C20.1,8.2,21.5,9,22.2,10.3z"
    *fill1*></path>
        <path d="M22.8,13.5c-0.2,1.4-1,2.7-2.4,3.5c-0.5,0.3-1.1,0.5-1.7,0.6v5.6c1.5-0.1,3.1-0.5,4.5-1.4   c3.1-1.8,5-5,5.2-8.3H22.8z"
        *fill2*></path>
        <path d="M13.8,15.2c-0.3-0.5-0.5-1.1-0.6-1.7H7.6C7.7,15,8.1,16.6,9,18c1.8,3.1,5,5,8.3,5.2v-5.6   C15.9,17.3,14.5,16.5,13.8,15.2z"
        *fill3*></path>
    </g>
    <g class="g_statuses">
        <path d="M11.9,10.8c0.1-0.4,0.3-0.8,0.5-1.2c0.8-1.4,2.1-2.4,3.5-2.9V3.8c-2.4,0.5-4.6,2-5.9,4.3   c-0.5,0.8-0.8,1.7-1,2.6H11.9z"
              *stroke4*></path>
        <path d="M24.1,10.8h2.8c-0.5-2.4-2-4.6-4.3-5.9c-0.8-0.5-1.7-0.8-2.6-1v2.9c0.4,0.1,0.8,0.3,1.2,0.5   C22.6,8,23.6,9.3,24.1,10.8z"
        *stroke1*></path>
        <path d="M24.1,14.8c-0.1,0.4-0.3,0.8-0.5,1.2c-0.8,1.4-2.1,2.4-3.5,2.9v2.8c2.4-0.5,4.6-2,5.9-4.3   c0.5-0.8,0.8-1.7,1-2.6H24.1z"
        *stroke2*></path>
        <path d="M11.9,14.8H9.1c0.5,2.4,2,4.6,4.3,5.9c0.8,0.5,1.7,0.8,2.6,1v-2.9c-0.4-0.1-0.8-0.3-1.2-0.5   C13.4,17.5,12.4,16.2,11.9,14.8z"
        *stroke3*></path>
    </g>
</svg>
  `,
  5: `
<svg *svgParams*>
    <g class="g_marker">
        <path d="M6 12.6C6 9.40001 7.3 6.40001 9.5 4.20001C11.7 2.00001 14.8 0.700012 18 0.700012C21.2 0.700012 24.2 2.00001 26.5 4.20001C28.7 6.40001 30 9.50001 30 12.6C30 14.8 29.3 17.4 28.1 20C26.9 22.6 25.4 25.2 23.8 27.5C22.2 29.8 20.7 31.8 19.5 33.3C18.9 34 18.4 34.6 18.1 35C17.8 34.6 17.3 34 16.7 33.3C15.5 31.9 13.9 29.8 12.4 27.5C10.8 25.2 9.3 22.6 8.1 20C6.8 17.4 6 14.8 6 12.6Z"
              fill="white"/>
        <path d="M18 36L17.5 35.4C17.1 35 16.6 34.4 16 33.7C14.9 32.4 13.3 30.3 11.6 27.9C9.80001 25.2 8.30001 22.6 7.20001 20.3C5.90001 17.4 5.20001 14.8 5.20001 12.7C5.30001 9.2 6.60001 6 9.00001 3.7C11.4 1.3 14.6 0 18 0C21.4 0 24.6 1.3 27 3.7C29.4 6.1 30.7 9.2 30.7 12.6C30.7 14.8 30 17.3 28.7 20.2C27.6 22.5 26.2 25.1 24.3 27.8C22.7 30.2 21 32.3 19.9 33.6C19.3 34.3 18.8 34.9 18.5 35.3L18 36ZM6.70001 12.6C6.70001 14.6 7.30001 16.9 8.60001 19.7C9.60001 22 11 24.4 12.8 27.1C14.4 29.5 16 31.5 17.1 32.8C17.4 33.2 17.8 33.6 18 33.9C18.3 33.6 18.6 33.2 18.9 32.8C20 31.5 21.6 29.4 23.2 27.1C25 24.5 26.4 22 27.4 19.7C28.6 17 29.3 14.6 29.3 12.6C29.3 9.6 28.1 6.8 26 4.7C23.9 2.6 21 1.4 18 1.4C15 1.4 12.1 2.6 10 4.7C7.90001 6.8 6.70001 9.6 6.70001 12.6Z"
              fill="#31B447"/>
    </g>
    <g class="g_types">
        <path d="M18.725 7.95154C19.4496 8.05603 20.1411 8.3231 20.7479 8.73274C21.3547 9.14239 21.8609 9.68399 22.2287 10.317C22.2784 10.4032 22.3046 10.4953 22.3485 10.5832L27.6705 8.85398C26.9522 7.03089 25.7292 5.45021 24.1449 4.29704C22.5607 3.14386 20.6806 2.46594 18.725 2.34265V7.95154Z"
            *fill5*/>
        <path d="M13.5677 10.7333C13.8989 9.99208 14.4127 9.34694 15.0611 8.85827C15.7095 8.36961 16.4712 8.05339 17.275 7.93921V2.35345C15.2604 2.4878 13.3286 3.20608 11.7156 4.42051C10.1025 5.63495 8.87822 7.29294 8.1922 9.19189L13.5677 10.7333Z"
        *fill1*/>
        <path d="M20.3989 17.0064C19.6924 17.4053 18.8974 17.6213 18.0862 17.6347C17.275 17.6481 16.4733 17.4585 15.754 17.0832L12.4659 21.6088C14.0722 22.6141 15.9219 23.163 17.8165 23.1966C19.711 23.2302 21.579 22.7473 23.2199 21.7997C23.4334 21.6765 23.6324 21.5384 23.8337 21.4028L20.3989 17.0064Z"
        *fill2*/>
        <path d="M22.8026 11.9603C22.9245 12.7017 22.873 13.4611 22.6523 14.1793C22.4316 14.8974 22.0476 15.5546 21.5304 16.0995L24.9827 20.5183C26.3896 19.2495 27.4251 17.6221 27.9782 15.8101C28.5314 13.9981 28.5814 12.0699 28.123 10.2316L22.8026 11.9603Z"
        *fill3*/>
        <path d="M11.2975 20.7502L14.5871 16.2225C14.039 15.7049 13.6244 15.0622 13.3788 14.3494C13.1333 13.6367 13.064 12.875 13.1769 12.1296L7.80015 10.5879C7.3978 12.4396 7.51112 14.3663 8.12778 16.1581C8.74444 17.9499 9.8408 19.5382 11.2975 20.7502V20.7502Z"
        *fill4*/>
    </g>
    <g class="g_statuses">
        <path d="M20 6.68658C21.2649 7.10353 22.3677 7.90582 23.1538 8.981L25.862 8.101C25.2276 7.02773 24.3802 6.09565 23.3721 5.36214C22.364 4.62863 21.2164 4.10919 20 3.83582V6.68658Z"
        *stroke5*/>
        <path d="M12.7216 9.16431C13.5112 8.00118 14.6642 7.13264 16 6.69458V3.84821C14.7667 4.12153 13.6037 4.64845 12.585 5.39547C11.5663 6.14249 10.7141 7.09327 10.0827 8.18738C10.0463 8.25038 10.019 8.31592 9.98438 8.37938L12.7216 9.16431Z"
        *stroke1*/>
        <path d="M20.1597 18.7712C18.8355 19.2478 17.3909 19.2736 16.0505 18.8444L14.3773 21.1473C15.5705 21.6632 16.86 21.9184 18.1597 21.8958C19.4594 21.8732 20.7393 21.5734 21.9139 21.0165L20.1597 18.7712Z"
        *stroke2*/>
        <path d="M12.8158 16.4913C12.0845 15.4727 11.6682 14.262 11.6183 13.0092L8.88098 12.2242C8.74193 14.6266 9.5541 16.9871 11.1418 18.7953L12.8158 16.4913Z"
        *stroke3*/>
        <path d="M24.3879 12.7858C24.3862 13.8985 24.0923 14.9911 23.5358 15.9546C23.4655 16.0766 23.3861 16.1898 23.3091 16.3052L25.0621 18.5489C25.8258 17.6326 26.399 16.5731 26.7481 15.4325C27.0972 14.2918 27.2152 13.093 27.0952 11.9062L24.3879 12.7858Z"
        *stroke4*/>
    </g>
</svg>
  `,
  6: `
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="g_marker">
        <path d="M6 12.6C6 9.40001 7.3 6.40001 9.5 4.20001C11.7 2.00001 14.8 0.700012 18 0.700012C21.2 0.700012 24.2 2.00001 26.5 4.20001C28.7 6.40001 30 9.50001 30 12.6C30 14.8 29.3 17.4 28.1 20C26.9 22.6 25.4 25.2 23.8 27.5C22.2 29.8 20.7 31.8 19.5 33.3C18.9 34 18.4 34.6 18.1 35C17.8 34.6 17.3 34 16.7 33.3C15.5 31.9 13.9 29.8 12.4 27.5C10.8 25.2 9.3 22.6 8.1 20C6.8 17.4 6 14.8 6 12.6Z"
              fill="white"/>
        <path d="M18 36L17.5 35.4C17.1 35 16.6 34.4 16 33.7C14.9 32.4 13.3 30.3 11.6 27.9C9.80001 25.2 8.30001 22.6 7.20001 20.3C5.90001 17.4 5.20001 14.8 5.20001 12.7C5.30001 9.2 6.60001 6 9.00001 3.7C11.4 1.3 14.6 0 18 0C21.4 0 24.6 1.3 27 3.7C29.4 6.1 30.7 9.2 30.7 12.6C30.7 14.8 30 17.3 28.7 20.2C27.6 22.5 26.2 25.1 24.3 27.8C22.7 30.2 21 32.3 19.9 33.6C19.3 34.3 18.8 34.9 18.5 35.3L18 36ZM6.70001 12.6C6.70001 14.6 7.30001 16.9 8.60001 19.7C9.60001 22 11 24.4 12.8 27.1C14.4 29.5 16 31.5 17.1 32.8C17.4 33.2 17.8 33.6 18 33.9C18.3 33.6 18.6 33.2 18.9 32.8C20 31.5 21.6 29.4 23.2 27.1C25 24.5 26.4 22 27.4 19.7C28.6 17 29.3 14.6 29.3 12.6C29.3 9.6 28.1 6.8 26 4.7C23.9 2.6 21 1.4 18 1.4C15 1.4 12.1 2.6 10 4.7C7.90001 6.8 6.70001 9.6 6.70001 12.6Z"
              fill="#31B447"/>
    </g>
    <g class="g_types">
        <path d="M27.3834 17.3375C28.0777 15.9116 28.4377 14.3462 28.4358 12.7602C28.434 11.1743 28.0703 9.6097 27.3726 8.18549L22.5283 10.9823C22.9766 12.1236 22.9757 13.3923 22.5258 14.533L27.3834 17.3375Z"
              *fill2*/>
        <path d="M18.7261 7.9517C19.9388 8.13238 21.038 8.76595 21.8023 9.72477L26.6466 6.92798C25.762 5.61164 24.5888 4.51443 23.2163 3.71985C21.8438 2.92528 20.3081 2.45428 18.7261 2.34271V7.9517Z"
              *fill5*/>
        <path d="M14.1897 9.72015C14.9478 8.74893 16.0538 8.11041 17.2739 7.93945V2.35352C15.6912 2.45748 14.1536 2.92404 12.78 3.71712C11.4064 4.51019 10.2335 5.60854 9.35217 6.92725L14.1897 9.72015Z"
              *fill6*/>
        <path d="M17.2739 17.5652C16.0612 17.3845 14.9619 16.7509 14.1977 15.7921L9.35345 18.5889C10.238 19.9052 11.4112 21.0025 12.7837 21.797C14.1562 22.5916 15.6919 23.0626 17.2739 23.1742V17.5652Z"
              *fill4*/>
        <path d="M21.8104 15.7967C21.0522 16.7679 19.9463 17.4065 18.7261 17.5775V23.1633C20.3088 23.0593 21.8464 22.5928 23.22 21.7997C24.5936 21.0067 25.7665 19.9083 26.6479 18.5897L21.8104 15.7967Z"
              *fill3*/>
        <path d="M13.4716 14.5345C13.0234 13.3932 13.0243 12.1245 13.4743 10.9838L8.61671 8.17938C7.92237 9.60522 7.56243 11.1707 7.56428 12.7566C7.56613 14.3425 7.92972 15.9071 8.62739 17.3313L13.4716 14.5345Z"
              *fill2*/>
    </g>
    <g class="g_statuses">
        <path d="M11.7445 11.4562L9.27863 10.0325C8.72488 11.8069 8.72658 13.7081 9.28351 15.4815L11.7485 14.0584C11.5683 13.2005 11.567 12.3146 11.7445 11.4562V11.4562Z"
              *stroke2*/>
        <path d="M24.2556 14.0606L26.7215 15.4843C27.2752 13.7099 27.2734 11.8087 26.7165 10.0353L24.2515 11.4584C24.4317 12.3163 24.4331 13.2022 24.2556 14.0606V14.0606Z"
              *stroke1*/>
        <path d="M20 6.68658C20.8341 6.96203 21.6028 7.40581 22.2584 7.99042L24.7272 6.56506C23.4645 5.19723 21.8159 4.24543 20 3.83582V6.68658Z"
              *stroke6*/>
        <path d="M16 18.8303C15.1659 18.5548 14.3972 18.1111 13.7416 17.5264L11.2728 18.9518C12.5354 20.3197 14.184 21.2715 16 21.6811V18.8303Z"
              *stroke4*/>
        <path d="M22.2556 17.5248C21.6009 18.1078 20.8331 18.5495 20 18.8223V21.6687C21.8143 21.2643 23.4617 20.3152 24.7214 18.9484L22.2556 17.5248Z"
              *stroke3*/>
        <path d="M13.7446 7.99213C14.3992 7.40917 15.167 6.96746 16 6.69458V3.84821C14.1857 4.25254 12.5384 5.20166 11.2786 6.56842L13.7446 7.99213Z"
              *stroke5*/>
    </g>
</svg>
  `
}
