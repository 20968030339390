<template>
  <div class="connectors__item">
    <div
      class="connectors__item--delete"
      @click="deleteConnector(connector.type)"
    ></div>
    <div class="connectors__item--info">
      <p class="info__text name">{{ connector.connectorName }}</p>
      <div
        class="info__img"
        :style="{
          '-webkit-mask': `url(/v2/connectors_and_sockets/${connector.imgName}) no-repeat center / contain`,
        }"
      ></div>
      <p class="info__text status available">Доступен</p>
    </div>
    <div class="connectors__item--price">
      <div class="price__item price__electricity">
        <input
          class="price__item--input"
          type="number"
          v-model="electricity"
          @blur="valueCheck(electricity, 'electricity')"
        />
        <span class="price__item--icon"></span>
        <div class="price__item--input-copy">
          <span style="opacity: 0">{{ electricity }}</span>
          <span>₴/kWh</span>
        </div>
      </div>
      <div class="price__item price__time">
        <input
          class="price__item--input"
          type="number"
          v-model="time"
          @blur="valueCheck(time, 'time')"
        />
        <span class="price__item--icon"></span>
        <div class="price__item--input-copy">
          <span style="opacity: 0">{{ time }}</span>
          <span>₴/ч</span>
        </div>
      </div>
      <div class="price__item price__parking">
        <input
          class="price__item--input"
          type="number"
          v-model="parking"
          @blur="valueCheck(parking, 'parking')"
        />
        <span class="price__item--icon"></span>
        <div class="price__item--input-copy">
          <span style="opacity: 0">{{ parking }}</span>
          <span>₴/ч</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_NEW_STATION_ACTION } from "@/store/constants"

export default {
  name: 'Connector',
  props: {
    connector: Object,
    deleteConnector: Function
  },
  data: () => ({
    electricity: '1.70',
    time: '0.01',
    parking: '0.01'
  }),
  methods: {
    updateStore () {
      this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
        updatedStationField: 'connectors',
        connectorId: this.connector.id,
        values: {
          electricity: this.electricity,
          time: this.time,
          parking: this.parking,
          type: this.connector.type
        }
      })
    },
    valueCheck (value, field) {
      if (!value) {
        this[field] = '0.00'
      }
    }
  },
  watch: {
    allData () {
      this.updateStore()
    }
  },
  computed: {
    allData () {
      const { electricity, time, parking } = this
      return {
        electricity,
        time,
        parking
      }
    }
  }
}
</script>

<style scoped lang="scss">
$green: #5ab464;
$grey: #545759;

.connectors__item {
  margin-right: 4%;
  flex-shrink: 0;
  flex-basis: 22%;
  min-height: 180px;
  position: relative;

  &:nth-last-child(-n + 4) {
    padding-bottom: 0;
  }
  &:nth-child(4n) {
    margin-right: 0;
  }
  &:hover &--delete {
    display: block;
  }
  &--delete {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: #545759;
    -webkit-mask: url(../../../../assets/img/close.svg) center center/contain no-repeat;
  }
  &--info {
    margin-bottom: 10px;
    text-align: center;

    .info__text {
      color: $green;
      font-size: 10px;

      &.name {
        color: $grey;
        max-width: 70%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        min-height: 30px;
      }
      &.status {
        padding-top: 5px;
      }
    }
    .info__img {
      background-color: $green;
      height: 55px;
    }
  }

  &--price {
    .price__item {
      position: relative;
      display: flex;
      overflow-x: auto;
      align-items: center;
      margin-bottom: 4px;
      padding-bottom: 2px;
      color: $grey;

      &:last-child {
        margin-bottom: 0;
      }
      &--icon {
        position: absolute;
        left: 0;
        bottom: 3px;
        height: 15px;
        width: 14px;
        background-color: #545759;
        -webkit-mask: url(../../../../assets/img/voltage.svg) center center/contain no-repeat;
        @media (max-width: 360px) {
          max-width: 8px;
          width: 100%;
        }
      }
      &.price__time .price__item--icon {
        -webkit-mask: url(../../../../assets/img/clock_sml.svg) center center/contain no-repeat;
        height: 14px;
      }
      &.price__parking .price__item--icon {
        -webkit-mask: url(../../../../assets/img/parking.svg) center center/contain no-repeat;
      }
      &--input {
        border: none;
        border-radius: 0;
        width: 100%;
        padding: 0 0 3px 20px;
        font-size: 10px;
        -moz-appearance: textfield;
        border-bottom: 1px solid rgba(84, 87, 89, 0.5);
        @media (max-width: 360px) {
          padding: 0 0 3px 10px;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      &--input.validated + .price__item--icon {
        background-color: #5DB461;
      }
      &--input.unvalidated {
        border-bottom: 1px solid #EB5757;
        & + .price__item--icon {
          background-color: #EB5757;
        }
      }
      &--input-copy {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 23px;
        font-size: 10px;
        line-height: 13px;
        pointer-events: none;
        @media (max-width: 375px) {
          left: auto;
        }
      }
    }
  }
}
</style>
