<template>
  <div class="nearby-places">
    <div>{{ $t('station_sidebar.near') }}:</div>
    <div class="list">
      <div v-for="(img, key) in images"
           :style="img.style"
           :title="img.title"
           :key="key"
           class="place-icon"></div>
    </div>
  </div>
</template>

<script>
const nearbyPlaces = {
  1: {
    imgName: 'bank',
    text: 'Bank & ATM'
  },
  2: {
    imgName: 'beauty',
    text: 'Beauty salon'
  },
  3: {
    imgName: 'car_rental',
    text: 'Car rental'
  },
  4: {
    imgName: 'car_wash',
    text: 'Car wash'
  },
  5: {
    imgName: 'car_dealer',
    text: 'Cars dealer'
  },
  6: {
    imgName: 'cinema',
    text: 'Cinema'
  },
  7: {
    imgName: 'dining',
    text: 'Dining'
  },
  8: {
    imgName: 'ev_parking',
    text: 'EV Parking'
  },
  9: {
    imgName: 'gas',
    text: 'Gas'
  },
  10: {
    imgName: 'grocery',
    text: 'Grocery'
  },
  11: {
    imgName: 'gym',
    text: 'Gym'
  },
  12: {
    imgName: 'home_and_garden',
    text: 'Home & Garden'
  },
  13: {
    imgName: 'hospital',
    text: 'Hospital & Clinic'
  },
  14: {
    imgName: 'hotel',
    text: 'Hotel'
  },
  15: {
    imgName: 'lodging',
    text: 'Lodging'
  },
  16: {
    imgName: 'park',
    text: 'Park'
  },
  17: {
    imgName: 'restroom',
    text: 'Restrooms'
  },
  18: {
    imgName: 'shopping',
    text: 'Shopping center'
  },
  19: {
    imgName: 'valet_parking',
    text: 'Valet Parking'
  },
  20: {
    imgName: 'wifi',
    text: 'WiFi'
  }
}

export default {
  name: 'NearbyPlaces',
  props: ['places'],
  computed: {
    images () {
      return this.places.map((i) => ({
        title: nearbyPlaces[i].text,
        style: {
          mask: `url(nearby_places/${nearbyPlaces[i].imgName}.svg) no-repeat center / contain`,
          '-webkit-mask': `url(nearby_places/${nearbyPlaces[i].imgName}.svg) no-repeat center / contain`
        }
      }))
    }
  }
}
</script>

<style scoped lang="scss">
.nearby-places {
  padding: 0 20px 15px;

  .list {
    padding: 5px 0 0 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .place-icon {
    height: 27px;
    width: 27px;
    background-color: #545759;
    margin-right: 3px;
  }
}
</style>
