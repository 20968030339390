export default {
  header: {
    login: 'Login',
    about_company: 'About company',
    about_service: 'About service',
    buy_station: 'Buy charge station'
  },
  auth_popup: {
    authorization: 'Authorization',
    reset_password_label: 'Reset password',
    reset_password: 'Reset password',
    register: 'Register',
    registration: 'Registration',
    our_application: 'Our application',
    agree_to_terms: 'Agree to the <a target="_blank" href="{url}" class="rools_link">terms</a>',
    form: {
      login: 'Email or phone number',
      email: 'Email',
      password: 'Password',
      repeat_password: 'Repeat password',
      name: 'Name',
      second_name: 'Second name',
      phone_number: 'Phone number',
      vin: 'VIN',
      plate: 'Plate',
      auto_vendor: 'Auto vendor',
      auto_model: 'Auto model',
      secret_word: 'Secret word'
    }
  },
  language: {
    name: 'Language'
  },
  profile: {
    profile_title: 'Profile',
    hello_word: 'Hello, ',
    balance: 'Balance: '
  },
  main_menu: {
    title: 'Menu',
    main_page: 'Main',
    charge_page: 'Charging',
    cars_page: 'My vehicles',
    statistic_page: 'Statistics',
    sales_page: 'Sales',
    my_stations_page: 'My stations',
    comments_page: 'Comments',
    new_stations_page: 'New stations',
    control_room_page: 'Control room'
  },
  mobile_modal: {
    app_is_better: 'The app is more convenient',
    work_from_phone: 'Download and work from your phone!'
  },
  station_sidebar: {
    go_to_site: 'Go to site',
    open: 'Open',
    near: 'Near',
    schedule: {
      all_day: '24/7'
    },
    comments: {
      month_ago: 'One month ago',
      year_ago: 'year ago',
      years_ago: 'years ago',
      'two-four_months_ago': 'months ago',
      'five-twelve_months_ago': 'months ago',
      today: 'today',
      days_ago: 'days ago',
      cancel_button: 'Cancel',
      send_button: 'Send'
    },
    rates: {
      time: 'h',
      parking: 'min',
      kWh: 'kW*h'
    }
  },
  filters: {
    kW: 'kW',
    sessions: 'Sessions',
    charged: 'Charged'
  },
  search: {
    title: 'Search',
    search_field_placeholder: 'Input station name'
  },
  statuses: {
    available: 'Available',
    preparing: 'Charging',
    charging: 'Charging',
    finishing: 'Charging',
    suspendedEVSE: 'Charging',
    suspendedEV: 'Charging',
    occupied: 'Charging',
    unavailable: 'Unavailable',
    faulted: 'Faulted',
    unknown: 'Unknown'
  },
  edit_station_info: {
    edit_station_name: 'Station name',
    edit_station_connectors_name: 'Connectors',
    edit_address_station: 'Station address',
    edit_station_description: 'Description',
    edit_site_station: 'Site of the station (company)',
    edit_button: 'Edit',
    incorrect_name: 'Incorrect name',
    incorrect_address: 'Incorrect address',
    edit_photo: 'Photos',
    save_button: 'Save'
  },
  edit_stations_images: {
    edit_photo: 'Add photo'
  },
  edit_station_connectors: {
    edit_connector: 'Add connector'
  },
  nearbyPlaces: {
    bank: 'Bank',
    beauty: 'Beauty',
    car_rental: 'Car rental',
    car_wash: 'Car wash',
    car_dealer: 'Car dealer',
    cinema: 'Cinema',
    dining: 'Dining',
    ev_parking: 'EV parking',
    gas: 'Gas',
    grocery: 'Grocery',
    gym: 'Gym',
    home_and_garden: 'Home and garden',
    hospital: 'Hospital',
    hotel: 'Hotel',
    lodging: 'Lodging',
    park: 'Park',
    restroom: 'Restroom',
    shopping: 'Shopping',
    valet_parking: 'Valet parking',
    wifi: 'WiFi'
  }
}
