export default {
  header: {
    login: 'Einloggen',
    about_company: 'Über Unternehmen',
    about_service: 'Über den Service',
    buy_station: 'Ladestation kaufen'
  },
  auth_popup: {
    authorization: 'Autorisierung',
    reset_password_label: 'Passwort zurücksetzen',
    reset_password: 'Passwort zurücksetzen',
    register: 'Registrieren',
    registration: 'Registrierung',
    our_application: 'Unsere Anwendung',
    agree_to_terms: 'Stimmen Sie den <a target="_blank" href="{ url }" class="rools_link"> Bedingungen </a>',
    form: {
      login: 'E-Mail oder Telefonnummer',
      email: 'Email',
      password: 'Passwort',
      repeat_password: 'Passwort wiederholen',
      name: 'Name',
      second_name: 'zweiter name',
      phone_number: 'Telefonnummer',
      vin: 'VIN',
      plate: 'Plate',
      auto_vendor: 'Auto vendor',
      auto_model: 'Auto model',
      secret_word: 'Secret word'
    }
  },
  language: {
    name: 'Sprache'
  },
  profile: {
    profile_title: 'Profil',
    hello_word: 'Willkommen, ',
    balance: 'Balance: '
  },
  main_menu: {
    title: 'Menü',
    main_page: 'Home',
    charge_page: 'Aufladen',
    cars_page: 'Meine Autos',
    statistic_page: 'Statistiken',
    sales_page: 'Umsatz',
    my_stations_page: 'Meine Stationen',
    comments_page: 'Kommentare',
    new_stations_page: 'Kommentare',
    control_room_page: 'Kontrollraum'
  },
  mobile_modal: {
    app_is_better: 'Die App ist bequemer',
    work_from_phone: 'Herunterladen und Arbeiten von Ihrem Telefon!'
  },
  station_sidebar: {
    go_to_site: 'Zur Site gehen',
    open: 'Öffnen',
    near: 'In der Nähe',
    schedule: {
      all_day: '24/7'
    },
    comments: {
      month_ago: 'Vor einem Monat',
      year_ago: 'vor einem Jahr',
      years_ago: 'vor Jahren',
      'two-four_months_ago': 'vor Monaten',
      'five-twelve_months_ago': 'vor Monaten',
      today: 'heute',
      days_ago: 'vor Tagen',
      cancel_button: 'Stornieren',
      send_button: 'Senden'
    },
    rates: {
      time: 'h',
      parking: 'min',
      kWh: 'kW*h'
    }
  },
  filters: {
    kW: 'kW',
    sessions: 'Sitzungen',
    charged: 'Aufgeladen'
  },
  search: {
    title: 'Suche',
    search_field_placeholder: 'Name der Eingabestation'
  },
  statuses: {
    available: 'verfügbar',
    preparing: 'Vorbereiten',
    charging: 'Aufladen',
    finishing: 'Beenden',
    suspendedEVSE: 'Angehalten',
    suspendedEV: 'Angehalten',
    occupied: 'Aufladen',
    unavailable: 'Nicht verfügbar',
    faulted: 'Fehlerhaft',
    unknown: 'Unknown'
  },
  edit_station_info: {
    edit_station_name: 'Stationsname',
    edit_station_connectors_name: 'Anschlüsse',
    edit_address_station: 'Stationsadresse',
    edit_station_description: 'Beschreibung',
    edit_site_station: 'Seite der Station (Firma)',
    edit_button: 'Bearbeiten',
    incorrect_name: 'falscher name',
    incorrect_address: 'Falsche Adresse',
    edit_photo: 'Fotos',
    save_button: 'Speichern'
  },
  edit_stations_images: {
    edit_photo: 'Foto hinzufügen'
  },
  edit_station_connectors: {
    edit_connector: 'Connector hinzufügen'
  }
}
