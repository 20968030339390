<template>
  <div id="content" class="content" @click="closeModals">
    <div class="map" id="map" ref="map"></div>
    <MarkerAction
      :coords="temporaryMarker.coords"
      @close="removeTemporaryMarker"
      v-if="temporaryMarker"
    />
  </div>
</template>

<script>
import api from '@/api'
import { getMarkerData } from '@/helpers/connectors'
import { createMarker } from '@/helpers/marker'
import { CustomMarkerclusterer as MarkerClusterer } from '@/lib/marker-cluster/custom-markerclusterer'
import {
  FILTER_STATIONS_EVENT,
  SHOW_STATION_INFO_EVENT
} from '@/constants'
import { eventBus } from '@/eventBus'
import silverThemeStyles from '@/data/googleMapsSilverThemeStyles'
import {
  GET_STATION_ACTION,
  GET_STATIONS_ACTION,
  SET_SELECTED_STATION_MUTATION,
  SET_NEW_STATION_MUTATION
} from '@/store/constants'
import MarkerAction from '@/components/pages/home/MarkerAction'

export default {
  name: 'Map',
  components: { MarkerAction },
  data: () => ({
    map: null,
    temporaryMarker: null,
    markers: {},
    markerCluster: null
  }),
  created () {
    eventBus.$on(FILTER_STATIONS_EVENT, this.filterMarkers)
    eventBus.$on(SHOW_STATION_INFO_EVENT, this.setCenter)
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    eventBus.$off(FILTER_STATIONS_EVENT, this.filterMarkers)
    eventBus.$off(SHOW_STATION_INFO_EVENT, this.setCenter)
    // TODO need to unsubscribe all listeners
  },
  methods: {
    init () {
      if (window.google && window.google.maps) {
        this.initMap()
      } else {
        setTimeout(() => {
          this.init()
        }, 500)
      }
    },
    initMarkers (stations) {
      this.createMarkers(stations)

      if (!this.markerCluster) {
        this.initMarkerCluster(this.markers)
      } else {
        this.markerCluster.addMarkers(this.markers, true)
      }
    },
    initMap () {
      this.map = new window.google.maps.Map(this.$refs.map, {
        zoom: 6,
        minZoom: 1,
        center: new window.google.maps.LatLng(48.02115, 36.01214),
        mapTypeId: 'roadmap',
        gestureHandling: 'greedy',
        styles: []
      })

      const styledMap = new window.google.maps.StyledMapType(silverThemeStyles)
      this.map.mapTypes.set('styled_map', styledMap)
      this.map.setMapTypeId('styled_map')

      this.map.addListener('click', e => {
        this.mapClickHandler(e)
      })

      this.map.addListener('idle', () => {
        const bounds = this.map.getBounds()
        const ne = bounds.getNorthEast().toJSON()
        const sw = bounds.getSouthWest().toJSON()
        this.getStations({ ne, sw })
      })

      window.map = this.map
    },
    async getStation (id) {
      try {
        const response = await api.getStation(id)
        this.initMarkers([response.body])
      } catch (err) {
        console.error(err)
      }
    },
    getStations ({ ne, sw }) {
      this.$store.dispatch(GET_STATIONS_ACTION, {
        nw: `${ne.lat.toFixed(6)},${sw.lng.toFixed(6)}`,
        se: `${sw.lat.toFixed(6)},${ne.lng.toFixed(6)}`
      }).then((result) => {
        this.initMarkers(result)
      })
    },
    mapClickHandler (e) {
      if (this.temporaryMarker) {
        this.removeTemporaryMarker()
      }

      this.temporaryMarker = {
        coords: {
          lat: e.latLng.lat(),
          lon: e.latLng.lng()
        },
        marker: new window.google.maps.Marker({
          position: e.latLng,
          map: this.map
        })
      }
    },
    initMarkerCluster (markers) {
      this.markerCluster = new MarkerClusterer(this.map, markers, {
        ignoreHidden: true,
        minimumClusterSize: 5,
        maxZoom: 17,
        gridSize: 80
      })
    },
    createMarkers (stations) {
      for (const id in stations) {
        const station = stations[id]
        const { connectorImg, regular, fast } = getMarkerData(station.conn)
        const connectorsData = { regular, fast, id }

        const marker = createMarker(connectorImg, station.geo, connectorsData)

        window.google.maps.event.addListener(marker, 'click', () => {
          this.$store.dispatch(GET_STATION_ACTION, station.id)
        })

        this.markers[station.id] = marker
      }
    },
    filterMarkers (connectorTypes) {
      // server filtering
    },
    async setCenter (station) {
      const { map, markers } = this

      map.panTo(new window.google.maps.LatLng(station.geo.lat, station.geo.lon))
      map.getZoom() < 8 && map.setZoom(8)

      if (!markers[station.id]) {
        await this.getStation(station.id)
      }

      await this.$store.dispatch(GET_STATION_ACTION, station.id)

      const zoomInterval = setInterval(() => {
        if (!markers[station.id].map || map.getZoom() < 13) {
          map.setZoom(map.getZoom() + 1)
        } else {
          clearInterval(zoomInterval)
        }
      }, 100)
    },
    closeModals () {
      this.$store.commit(SET_SELECTED_STATION_MUTATION, null)
      this.$store.commit(SET_NEW_STATION_MUTATION, null)
    },
    removeTemporaryMarker () {
      if (this.temporaryMarker) {
        this.temporaryMarker.marker.setMap(null)
        this.temporaryMarker = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  height: 100%;
}

@media (max-width: 1125px) {
  .map {
    height: 100vh;
  }
}
</style>
