export const GET_STATIONS_ACTION = 'GET_STATIONS'
export const GET_STATION_ACTION = 'GET_STATION'
export const GET_SEARCHED_STATIONS_ACTION = 'GET_SEARCHED_STATIONS'
export const SEND_STATION_ACTION = 'SEND_STATION'
export const TOGGLE_MENU_ACTION = 'TOGGLE_MENU'
export const TOGGLE_AUTH_MENU_ACTION = 'TOGGLE_AUTH_MENU'
export const ADD_NEW_STATION_ACTION = 'ADD_NEW_STATION'
export const UPDATE_NEW_STATION_ACTION = 'UPDATE_NEW_STATION'

export const SET_STATIONS_MUTATION = 'SET_STATIONS'
export const SET_STATION_MUTATION = 'SET_STATION'
export const SET_SELECTED_STATION_MUTATION = 'SET_SELECTED_STATION'
export const SET_MENU_VISIBILITY_MUTATION = 'SET_MENU_VISIBILITY'
export const SET_AUTH_MENU_VISIBILITY_MUTATION = 'SET_AUTH_MENU_VISIBILITY'
export const SET_MODAL_VISIBILITY_MUTATION = 'SET_MODAL_VISIBILITY'
export const SET_NEW_STATION_MUTATION = 'SET_NEW_STATION'
