<template>
  <div class="add-station__item item__descr">
    <h4 class="descr__heading item__descr">{{ $t('new_station_sidebar.description.title') }}</h4>
    <div class="descr__grid">
      <div class="descr__item">
        <input
          :class="['descr__item--input', {
            validated: name.validated && name.value,
            unvalidated: (!name.validated && name.value) || (showRequiredFields && !name.validated)
          }]"
          type="text"
          maxlength="120"
          :placeholder="$t('new_station_sidebar.description.input_name')"
          v-model="name.value"
        />
        <div class="descr__item--icon name"></div>
        <p class="descr__item--text" v-if="!name.value && showRequiredFields">
          {{ $t('new_station_sidebar.description.input_name_error') }}
        </p>
      </div>
      <div class="descr__item">
        <input
          class="descr__item--input validated"
          type="text"
          maxlength="250"
          :placeholder="$t('new_station_sidebar.description.input_address')"
          disabled
          :value="this.newStation.address"
        />
        <div class="descr__item--icon location"></div>
      </div>
      <div class="descr__item">
        <input
          :class="['descr__item--input', {
            validated: site.validated && site.value,
            unvalidated: !site.validated && site.value
          }]"
          type="text"
          :placeholder="$t('new_station_sidebar.description.input_site')"
          v-model="site.value"
        />
        <div class="descr__item--icon site"></div>
        <p class="descr__item--text" v-if="!site.validated && site.value">
          {{ $t('new_station_sidebar.description.input_site_error') }}
        </p>
      </div>
      <div class="descr__item">
        <input
          :class="['descr__item--input', description && 'validated']"
          type="text"
          maxlength="500"
          :placeholder="$t('new_station_sidebar.description.input_description')"
          v-model="description"
        />
        <div class="descr__item--icon description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { UPDATE_NEW_STATION_ACTION } from '@/store/constants'

export default {
  name: 'Description',
  props: {
    showRequiredFields: Boolean
  },
  data: () => ({
    name: {
      value: '',
      validated: false
    },
    site: {
      value: '',
      validated: false
    },
    description: ''
  }),
  methods: {
    updateStore (name, value) {
      this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
        updatedStationField: name,
        values: value
      })
    },
    urlValidation () {
      const regexp = new RegExp('^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$')

      regexp.test(this.site.value)
        ? this.site.validated = true
        : this.site.validated = false

      this.$emit('url-checker', this.site.validated)
    }
  },
  watch: {
    'name.value' (value) {
      value
        ? this.name.validated = true
        : this.name.validated = false

      this.updateStore('name', value)
    },
    'site.value' (value) {
      this.urlValidation()

      this.updateStore('site', value)
    },
    description (value) {
      this.updateStore('description', value)
    }
  },
  computed: mapState(['newStation'])
}
</script>

<style scoped lang="scss">
$red: #EB5757;

.add-station__item.item__descr {
  border-bottom: none;
}
.descr__heading {
  padding-bottom: 15px;
}
.descr__grid {
  .descr__item {
    position: relative;
    margin-bottom: 20px;

    &--icon {
      position: absolute;
      top: 4px;
      left: 3px;
      width: 20px;
      height: 20px;
      background-color: #bdbdbd;
      -webkit-mask: url(../../../../assets/img/station_name_icon.svg) center
        center / contain no-repeat;

      &.location {
        -webkit-mask: url(../../../../assets/img/station_address_icon.svg)
          center center / contain no-repeat;
      }
      &.site {
        -webkit-mask: url(../../../../assets/img/station_site_icon.svg) center
          center / contain no-repeat;
      }
      &.description {
        -webkit-mask: url(../../../../assets/img/station_information_icon.svg)
          center center / contain no-repeat;
      }
    }
    &--input {
      padding: 3px 0 0 30px;
      border-bottom: 1px solid #bdbdbd;
      border-radius: 0;
      font-size: 16px;
      font-weight: 700;
      color: #000;

      &::placeholder {
        color: #bdbdbd;
      }
    }
    &--text {
      font-size: 10px;
      color: $red;
      position: absolute;
    }

    &--input.validated + .descr__item--icon {
      background-color: #5ab464;
    }
    &--input.unvalidated {
      border-bottom: 1px solid #eb5757;
    }
    &--input.unvalidated + .descr__item--icon {
      background-color: #eb5757;
    }
  }
}
</style>
