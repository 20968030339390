<template>
  <div class="road_builder">
    <div class="close_road_builder">
      <img src="../../../assets/img/close.svg" alt="" @click="$emit('close')">
    </div>
    <div class="d_flex">
      <div class="left_part">
        <div class="place_photo">
          <img src="../../../assets/img/builder_img.png" alt="">
        </div>
        <div class="address_info d_flex">
          <div class="top">
            <h3 class="address_street">{{ address }}</h3>
            <p class="country">{{ country }}</p>
          </div>
          <div class="bottom">
            <p class="coord">{{ getCoord(coords.lat) + ', ' + getCoord(coords.lon) }}</p>
          </div>
        </div>
      </div>
      <div class="right_part">
        <div class="plan_route">
          <div class="plan_route_item">
            <img src="../../../assets/img/route_icon.svg" alt="">
          </div>
          <p class="plan_route_text">Проложить маршрут</p>
        </div>
        <div class="add_station" @click.stop="addStation">
          <div class="add_station_item">
            <img src="../../../assets/img/add_station_icon.svg" alt="">
          </div>
          <p class="add_station_text">Добавить станцию</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { ADD_NEW_STATION_ACTION } from '@/store/constants'

export default {
  name: 'RoadBuilder',
  props: {
    coords: Object
  },
  data: () => ({
    geocoderResult: null
  }),
  async created () {
    await this.getPlaceData()
  },
  computed: {
    address () {
      if (!this.geocoderResult) {
        return null
      }

      let address = ''

      const cityObj = this.geocoderResult.address_components.find((el) => el.types.includes('locality'))
      const regionObj = this.geocoderResult.address_components.find((el) => el.types.includes('administrative_area_level_2'))
      const streetObj = this.geocoderResult.address_components.find((el) => el.types.includes('route'))
      const streetNumberObj = this.geocoderResult.address_components.find((el) => el.types.includes('street_number'))

      if (cityObj) {
        address += cityObj.long_name
      } else if (regionObj) {
        address += regionObj.long_name
      }

      if (streetObj) {
        const street = (address) ? `, ${streetObj.long_name}` : streetObj.long_name
        address += street
      }

      if (streetNumberObj) {
        const streetNumber = (streetObj) ? `, ${streetNumberObj.long_name}` : streetNumberObj.long_name
        address += streetNumber
      }

      if (address) {
        return address
      }

      return 'Unknown place'
    },
    country () {
      if (!this.geocoderResult) {
        return null
      }

      const countryObj = this.geocoderResult.address_components.find((el) => el.types.includes('country'))

      if (countryObj) {
        return countryObj.long_name
      }

      return null
    }
  },
  methods: {
    getCoord (num) {
      return num.toFixed(6)
    },
    async getPlaceData () {
      try {
        const response = await api.getAddressByCoords(this.coords)
        this.geocoderResult = (response.body.results && response.body.results.length) ? response.body.results[0] : null
      } catch (err) {
        console.error(err)
      }
    },
    addStation () {
      console.log('addStation')
      this.$store.dispatch(ADD_NEW_STATION_ACTION, {
        geo: this.coords,
        address: this.address
      })
      this.$emit('close')
    }
  },
  watch: {
    async coords () {
      await this.getPlaceData()
    }
  }
}
</script>

<style lang="scss" scoped>
.road_builder {
  position: absolute;
  left: 50%;
  bottom: 8%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 100%;
  max-width: 445px;
  height: 90px;
  content: '';
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  @media (max-width: 1023px) {
    bottom: 15%;
  }
  @media (max-width: 400px) {
    bottom: 27%;
  }
  .d_flex {
    justify-content: space-between;
    height: 100%;
    .left_part {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .right_part {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        width: 1px;
        height: 90%;
        background: #E0E0E0;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.close_road_builder {
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  z-index: 2;
  img {
    margin: 0 auto;
    width: 10px;
    height: 10px;
  }
}
.place_photo {
  width: 100%;
  max-width: 115px;
  height: auto;
  margin-right: 13px;
  @media (max-width: 475px) {
    display: none;
  }
}
.address_info.d_flex {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  max-width: 180px;

  h3.address_street {
    font-size: 16px;
    color: #333;
    font-weight: 700;
    max-width: 175px;
    line-height: 14px;
  }
  p.country {
    font-size: 10px;
    color: #333;
    font-weight: 400;
  }
  p.coord {
    font-size: 10px;
    color: #BDBDBD;
  }
}
.plan_route,
.add_station {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;

}
.plan_route {
  position: relative;
  margin-right: 20px;

}
.plan_route_item,
.add_station_item {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #BDBDBD;
  border-radius: 50%;
  margin-bottom: 5px;
  img {
    margin: 0 auto;
  }
}
.plan_route_text,
.add_station_text {
  font-size: 10px;
  color: #5AB464;
  line-height: 9px;
  max-width: 50px;
}
</style>
