<template>
  <ModalWrapper :show-backdrop="isVisible" @closeModal="isVisible = false">
    <div class="mobile_popup" :class="{ active: isVisible }">
      <div class="popup_close" @click="isVisible = false">
        <img src="../assets/img/close.svg" alt="">
      </div>
      <div class="popup_title">{{ $t('mobile_modal.app_is_better') }}</div>
      <div class="popup_img">
        <img src="../assets/img/popup_img.svg" alt="Autoenterprise">
      </div>
      <div class="popup_subtitle">
        AE Charging Point
      </div>
      <div class="popup_text">
        {{ $t('mobile_modal.work_from_phone') }}
      </div>
      <div class="popup_links">
        <div class="popup_links_item">
          <a href="https://apps.apple.com/ru/app/ae-charging-point/id1274189210">
            <img src="../assets/img/app_market.svg" alt="apple">
          </a>
        </div>
        <div class="popup_links_item">
          <a href="https://play.google.com/store/apps/details?id=ua.com.autoenterprise.charge.chargeae&hl=ru">
            <img src="../assets/img/google_play.svg" alt="google">
          </a>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
// import * as MobileDetect from 'mobile-detect'
import { popupMixin } from '@/mixins/popupMixin'

export default {
  name: 'MobilePopup',
  mixins: [
    popupMixin
  ],
  created () {
    // todo maybe its unnecessary to show that
    // const md = new MobileDetect(window.navigator.userAgent)

    // if (md.mobile() && process.env.NODE_ENV !== 'development') {
    //   setTimeout(() => {
    //     this.isVisible = true
    //   }, 15000)
    // }
  }
}
</script>

<style scoped lang="scss">
.mobile_popup {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 95%;
  max-width: 375px;
  padding: 40px;
  background-color: #fff;
  background-image: url("../assets/img/back_popup.jpg");
  display: none;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.57);
  &.active {
    display: flex;
  }
  .popup_close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .popup_title {
    font-size: 28px;
    line-height: 30px;
    max-width: 210px;
    text-align: center;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 20px;

  }
  .popup_img {
    margin-bottom: 20px;
  }
  .popup_subtitle {
    font-size: 20px;
    font-weight: 700;
    color: #27AE60;
    margin-bottom: 20px;
    @media (min-width:375px) {
      font-size: 28px;
    }

  }
  .popup_text {
    margin-bottom: 30px;
    text-align: center;
  }
  .popup_links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    &_item {
      width: 120px;
      display: flex;
      justify-content: center;
      @media (min-width:375px) {
        width: 150px;
      }
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
}
</style>
