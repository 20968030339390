<template>
  <header id="header" class="header">
    <div class="container d_flex">
      <a href="/" class="logo d_flex">
        <img src="../assets/img/logo.svg" alt="logo">
        <span>EV Charge Network</span>
        </a>
      <div class="app_links">
        <div class="app_links_inner d_flex">
          <div class="app_links_inner_item d_flex">
            <img src="/v2/connectors_and_sockets/j-1772-01.svg" alt="1">
            <img src="/v2/connectors_and_sockets/type2_socket2-01.svg" alt="1">
            <img src="/v2/connectors_and_sockets/type2_soket2-02-01.svg" alt="1">
          </div>
        <a href="https://www.autoenterprise.com.ua/zarjadnie_ystrojstva/"><h2>{{ $t('header.buy_station') }}</h2></a>
          <div class="app_links_inner_item d_flex">
            <img src="/v2/connectors_and_sockets/chademo.svg" alt="1">
            <img src="/v2/connectors_and_sockets/ccs_type1.svg" alt="1">
            <img src="/v2/connectors_and_sockets/ccs_type2.svg" alt="1">
          </div>
        </div>
      </div>
        <ul class="d_flex">
          <li class="login" @click="openAuthPopup"><span><img src="../assets/img/Union.svg" alt="">{{ $t('header.login') }}</span></li>
          <div class="burger_menu" :class="{ active: menuVisibility }" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </ul>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import { TOGGLE_AUTH_MENU_ACTION, TOGGLE_MENU_ACTION } from '@/store/constants'

export default {
  name: 'Header',
  computed: {
    ...mapState([
      'menuVisibility'
    ])
  },
  methods: {
    toggleMenu () {
      this.$store.dispatch(TOGGLE_MENU_ACTION)
    },
    openAuthPopup () {
      this.$store.dispatch(TOGGLE_AUTH_MENU_ACTION)
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  position: fixed;
  left: 0;
  right: 0;
  z-index: 4;
  background: #FCFCFC;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 15px 0;
  height: 62px;
  display: flex;
  align-items: center;
  @media (min-width: 1180px){}
  @media (min-width: 1251px) {
    padding: 0;
    height: 86px;
  }
  .container{
    align-items: stretch;
    @media (max-width:1250px) {
      align-items: center;
    }
  }
  .logo {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    span {
      color: #000;
      font-weight: 800;
      font-size: 20px;
      letter-spacing: 0.6px;
      @media (max-width: 575px) {
        font-size: 16px;
        letter-spacing: 0;
      }
    }
    @media (max-width: 1250px) {
      align-items: center;
    }
  }
  h1 {
    display: flex;
    align-items: center;
    @media (max-width:992px) {
      font-size: 18px;
      img {
        width: 45px;
      }
    }
    a{
      font-weight: normal;
      font-size: 27px;
      line-height: 32px;
      color: #3F3F3F;
    }
  }
  .app_links{
    padding: 15px;
    //margin-bottom: 20px;
    @media (max-width:1250px) {
      display: none;
    }
    a {
      margin: 0 5px;
      transition: all 200ms ease-in-out;
      &:hover {
        h2 {
          background-color: #31B447;
          color: #fff;
          transform: scale(1.05);
        }
      }
      h2{
        width: 100%;
        text-align: center;
        color: #31B447;
        border: 1px solid #31B447;
        border-radius: 4px;
        padding: 10px 20px;
        font-weight: 400;
        transition: all 200ms ease-in-out;

      }
    }

    &_inner{
      width: 100%;
      justify-content: center;
      flex-wrap: nowrap;
      &_item {
          display: flex;
          align-items: center;
          color: #303030;
          padding: 3px 2px;
          img {
            margin: 0 5px;
            width: 50px;
            height: 40px;
            opacity: 0.8;
          }
      }
    }
  }
}

.mobile_popup {
  position: relative;
  top: -88%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 95%;
  max-width: 375px;
  padding: 40px;
  background-image: url("../assets/img/back_popup.jpg");
  display: none;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.57);
  &.active {
    display: flex;
  }
  .popup_close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .popup_title {
    font-size: 28px;
    line-height: 30px;
    max-width: 210px;
    text-align: center;
    font-weight: 300;
    font-style: normal;
    margin-bottom: 20px;

  }
  .popup_img {
    margin-bottom: 20px;
  }
  .popup_subtitle {
    font-size: 20px;
    font-weight: 700;
    color: #27AE60;
    margin-bottom: 20px;
    @media (min-width:375px) {
      font-size: 28px;
    }

  }
  .popup_text {
    margin-bottom: 30px;
    text-align: center;
  }
  .popup_links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    &_item {
      width: 120px;
      @media (min-width:375px) {
        width: 150px;
      }
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
}

li.login {
  color: #fff;
  cursor: pointer;
  margin-right: 35px;
  span {
    color: #fff;
    padding: 5px 15px 5px 24px;
    background-color: #5AB464;
    position: relative;
    border-radius: 5px;
    img {
      position: absolute;
      left: 8px;
      top:50%;
      transform: translateY(-50%);
    }
  }
  @media (max-width: 575px) {
    margin-right: 10px;
  }
}

</style>
