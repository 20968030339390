import { ClusterIcon } from '@/lib/marker-cluster/cluster-icon'
import { connectorTypeColors } from '@/data'
import { createClusterChart } from '@/helpers/cluster'

export class CustomClusterIcon extends ClusterIcon {
  getImageElementHtml () {
    const imgSize = 48
    let allRegular = 0
    let allFast = 0
    this.cluster_.markers_.forEach((marker) => {
      if (marker.getVisible()) {
        allRegular += marker.connectorsData.regular.count
        allFast += marker.connectorsData.fast.count
      }
    })

    if (!allFast && !allRegular) {
      return null
    }

    const chart = createClusterChart([
      { value: allFast, color: connectorTypeColors.fast },
      { value: allRegular, color: connectorTypeColors.regular }
    ], imgSize)

    const str = new XMLSerializer().serializeToString(chart)
    const encodedData = window.btoa(str)
    const styles = `transform: translate(${(this.style.width - imgSize) / 2}px, ${(this.style.height - imgSize) / 2}px)`
    return `<img aria-hidden='true' style="${styles}" src='data:image/svg+xml;base64,${encodedData}'/>`
  }

  show () {
    if (this.div_) {
      this.div_.className = this.className_
      this.div_.style.cssText = this.createCss_(
        this.getPosFromLatLng_(this.center_)
      )

      this.div_.innerHTML = this.getImageElementHtml()
      this.div_.style.display = ''
    }
    this.visible_ = true
  }

  onAdd () {
    let cMouseDownInCluster
    let cDraggingMapByCluster

    const mc = this.cluster_.getMarkerClusterer()

    const [major, minor] = window.google.maps.version.split('.')

    const gmVersion = parseInt(major, 10) * 100 + parseInt(minor, 10)

    this.div_ = document.createElement('div')
    if (this.visible_) {
      this.show()
    }

    this.getPanes().overlayMouseTarget.appendChild(this.div_)

    // Fix for Issue 157
    this.boundsChangedListener_ = window.google.maps.event.addListener(
      this.getMap(),
      'bounds_changed',
      () => {
        cDraggingMapByCluster = cMouseDownInCluster
      }
    )

    window.google.maps.event.addDomListener(this.div_, 'mousedown', () => {
      cMouseDownInCluster = true
      cDraggingMapByCluster = false
    })

    // March 1, 2018: Fix for this 3.32 exp bug, https://issuetracker.google.com/issues/73571522
    // But it doesn't work with earlier releases so do a version check.
    if (gmVersion >= 332) {
      // Ugly version-dependent code
      window.google.maps.event.addDomListener(this.div_, 'touchstart', (e) => {
        e.stopPropagation()
      })
    }

    window.google.maps.event.addDomListener(this.div_, 'click', (e) => {
      cMouseDownInCluster = false
      if (!cDraggingMapByCluster) {
        /**
         * This event is fired when a cluster marker is clicked.
         * @name MarkerClusterer#click
         * @param {Cluster} c The cluster that was clicked.
         * @event
         */
        window.google.maps.event.trigger(mc, 'click', this.cluster_)
        window.google.maps.event.trigger(mc, 'clusterclick', this.cluster_) // deprecated name

        // The default click handler follows. Disable it by setting
        // the zoomOnClick property to false.
        if (mc.getZoomOnClick()) {
          // Zoom into the cluster.
          const mz = mc.getMaxZoom()
          const theBounds = this.cluster_.getBounds()
          mc.getMap().fitBounds(theBounds)
          // There is a fix for Issue 170 here:
          setTimeout(() => {
            mc.getMap().fitBounds(theBounds)
            // Don't zoom beyond the max zoom level
            if (mz !== null && mc.getMap().getZoom() > mz) {
              mc.getMap().setZoom(mz + 1)
            }
          }, 100)
        }

        // Prevent event propagation to the map:
        e.cancelBubble = true
        if (e.stopPropagation) {
          e.stopPropagation()
        }
      }
    })

    window.google.maps.event.addDomListener(this.div_, 'mouseover', () => {
      /**
       * This event is fired when the mouse moves over a cluster marker.
       * @name MarkerClusterer#mouseover
       * @param {Cluster} c The cluster that the mouse moved over.
       * @event
       */
      window.google.maps.event.trigger(mc, 'mouseover', this.cluster_)
    })

    window.google.maps.event.addDomListener(this.div_, 'mouseout', () => {
      /**
       * This event is fired when the mouse moves out of a cluster marker.
       * @name MarkerClusterer#mouseout
       * @param {Cluster} c The cluster that the mouse moved out of.
       * @event
       */
      window.google.maps.event.trigger(mc, 'mouseout', this.cluster_)
    })
  }
}
