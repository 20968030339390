<template>
  <div class="schedule_modal_item" >
    <div class="day_and_check">
      <p class="day__name">{{ day.name }}</p>
      <label class="checkbox_day">
        <input
          type="checkbox"
          class="check"
          :checked="day.isWorking"
          @click="toggleWorking(day)"
        />
        <span class="checkmark"></span>
      </label>
      <p>{{ day.isWorking ? "Открыто" : "Закрыто" }}</p>
    </div>
    <div class="from-to-time">
      <div
        class="from-to-time__hours"
        v-for="(hours, hoursItem) in day.workingHours"
        :key="hoursItem"
      >
        <div :class="['open_from', { hidden: !day.isWorking }]">
          <p>с</p>
          <ScheduleSelect
            :day="day"
            :period="'from'"
            :hoursItem="hoursItem"
            @delete-working-hours="deleteWorkingHours"
          />
        </div>
        <div :class="['open_to', { hidden: !day.isWorking || day.fullTime }]">
          <p>по</p>
          <ScheduleSelect
            :day="day"
            :period="'to'"
            :hoursItem="hoursItem"
            @delete-working-hours="deleteWorkingHours"
          />
        </div>
        <div class="hours__editing">
          <p
            v-if="hoursItem === 0"
            :class="['add_time', {
              inactive:
                !day.isWorking ||
                day.fullTime ||
                day.workingHours.length > 1 ||
                day.workingHours[0].to > 22
            }]"
            @click="addWorkingHours(day)"
          >
            Добавить часы работы
          </p>
          <p
            v-else
            class="delete_time"
            @click="deleteWorkingHours(day)"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleSelect from './ScheduleSelect'

export default {
  name: 'ScheduleDay',
  components: {
    ScheduleSelect
  },
  props: {
    day: Object
  },
  methods: {
    addWorkingHours (day) {
      const workingHours = day.workingHours

      workingHours.length < 2 &&
      workingHours.push({
        from:
          workingHours.length === 0
            ? 9
            : workingHours[workingHours.length - 1].to + 1,
        to: 24
      })
    },
    deleteWorkingHours (day) {
      if (day.workingHours.length > 1) {
        day.workingHours.pop()
      }
    },
    toggleWorking (day) {
      day.isWorking = !day.isWorking

      if (!day.isWorking) {
        this.deleteWorkingHours(day)
      }
    }
  }
}
</script>

<style scoped>

</style>
