import ru from './ru'
import en from './en'
import de from './de'
import ua from './ua'
import pl from './pl'
import pt from './pt'

export default {
  en,
  ru,
  pt,
  ua,
  pl,
  de
}
