<template>
  <div class="search_group" :class="{ 'active': station, 'mobile-active': mobileActive }"
       v-touch:swipe="swipeHandler">
    <div class="search_group_close" @click="close">
      <img src="../../../../assets/img/close.svg" alt="close">
    </div>
    <div class="inner_wrap" ref="station-info" v-if="station">
      <div class="search_group_info_station active">
        <div class="title_wrap">
          <h3 id="station_name">{{ station.name }}</h3>
          <h4 id="station_connectors">{{ connectors }}</h4>
          <h5 id="station_address">{{ station.address }}</h5>
          <p id="station_description">
            {{ station.description}}
          </p>
          <div class="d_flex site_and_rating">
            <a :href="station.link" target="_blank" :style="{ visibility: (station.link) ? 'visible' : 'hidden' }">
              {{ $t('station_sidebar.go_to_site') }}
            </a>
            <p class="rating_average">
              <span>{{ station.rating }}</span>
            </p>
          </div>

        </div>
        <div class="time_work_wrap d_flex">
          <div class="icon"><img src="../../../../assets/img/clock.svg" alt="clock"></div>
          <div class="time">{{ $t('station_sidebar.open') }}: {{ $t('station_sidebar.schedule.all_day') }}</div>
        </div>
        <NearbyPlaces v-if="station.features && station.features.length" :places="station.features"/>
      </div>
      <div class="search_group--footer" v-if="station.connectors.length">
        <div id="connectors_wrap" class="connectors_wrap">
          <Connectors :connectors="station.connectors"/>
        </div>
        <StationImages :images="station.photos" v-if="station.photos.length"/>
        <Comments :comments="station.comments"/>
      </div>
    </div>
  </div>
</template>

<script>
import Connectors from '@/components/pages/home/station-info/Connectors'
import Comments from '@/components/pages/home/station-info/Comments'
import StationImages from '@/components/pages/home/station-info/StationImages'
import NearbyPlaces from '@/components/pages/home/station-info/NearbyPlaces'
import * as MobileDetect from 'mobile-detect'
import { connectorTypes } from '@/data'
import { SET_SELECTED_STATION_MUTATION } from '@/store/constants'

export default {
  name: 'StationInfo',
  components: {
    Connectors,
    Comments,
    StationImages,
    NearbyPlaces
  },
  props: {
    station: Object
  },
  data: () => ({
    mobileActive: false
  }),
  watch: {
    'station.id' () {
      this.$nextTick(() => {
        const el = document.querySelector('.search_group .inner_wrap')
        if (el) {
          el.scrollTop = 0
        }
      })
    }
  },
  computed: {
    connectors () {
      if (!this.station) {
        return ''
      }
      return this.station.connectors
        .filter((val, i, arr) => arr.indexOf(val) === i)
        .map(({ type }) => connectorTypes[type].name)
        .join(', ')
    }
  },
  methods: {
    swipeHandler (direction) {
      const md = new MobileDetect(window.navigator.userAgent)
      if (!md.mobile()) {
        return
      }
      // console.log(this.$refs['station-info'].scrollTop, direction)
      switch (direction) {
        case 'top':
          this.mobileActive = true
          break
        case 'bottom':
          if (this.$refs['station-info'].scrollTop !== 0) {
            break
          }
          // if (this.mobileActive) {
          this.mobileActive = false
          // } else {
          this.$emit('hideStationInfo')
          // }
          break
      }
    },
    close () {
      this.$store.commit(SET_SELECTED_STATION_MUTATION, null)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/includes/mixin_scroll_reset";
.search_group {
  z-index: 12;
}

.search_group .inner_wrap {
  @include scrollReset(none, #5db45d);
}
.search_group .slider {
  @include scrollReset(none, #5db45d);
}
.inner_wrap {
  max-height: 100vh;
}
.title_wrap {
  background-image: url("../../../../assets/img/search_backround.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.search_group--footer {
  padding: 15px 20px 60px;
  //top: 61px;
}

.search_group_info_station {
  border-bottom: 1px solid #dcd9d9;
}
.close_button_search_group {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 30%;
  height: 50px;
  border-radius: 0;
  background-color: #fff;
  padding: 0;
  span {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    background-color: transparent;
    img {
      transition: all 0.3s ease-in-out;
      transform: rotate(180deg);
    }
  }
}

@media all and (min-width: 1126px) {
  .search_group {
    //top: 127px;
  }
}
@media (max-width: 575px) {
  .search_group {
    left: 50%;
    transform: translateX(-50%);
    top: 150%;
    width: 95%;
    max-width: none;
    margin: 0 auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    &.active {
      top: 30%;
      left: 50%;
    }
    &_close {
     display: none;
    }
    .title_wrap {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .inner_wrap {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    &.mobile-active {
      top: 61px;
      .close_button_search_group {
        outline: 0;
        bottom:10%;
        span {
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  .close_button_search_group {
    display: block;
  }
}
</style>
