import ModalWrapper from '@/components/ModalWrapper'

export const popupMixin = {
  components: {
    ModalWrapper
  },
  data: () => ({
    isVisible: false
  }),
  methods: {
    showPopup () {
      this.isVisible = true
    },
    hidePopup () {
      this.isVisible = false
    },
    togglePopupVisibility () {
      this.isVisible = !this.isVisible
    }
  }
}
