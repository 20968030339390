import { MarkerClusterer } from './markerclusterer'
import { eventBus } from '@/eventBus'
import { FILTER_STATIONS_EVENT } from '@/constants'

export class CustomMarkerclusterer extends MarkerClusterer {
  onAdd () {
    const self = this
    super.onAdd()
    eventBus.$on(FILTER_STATIONS_EVENT, this.onFilterHandler.bind(self))
  }

  onFilterHandler () {
    setTimeout(() => {
      this.repaint()
    }, 200)
  }

  onRemove () {
    eventBus.$off(FILTER_STATIONS_EVENT, this.onFilterHandler.bind(self))
    super.onRemove()
  }
}
