<template>
  <div class="add-station__item item__photos">
    <h4 class="photos__heading item__heading">{{ $t('new_station_sidebar.photos_title') }}</h4>
    <div class="photos__grid">
      <div
        class="photos__item"
        v-for="img in photos"
        :key="img.id"
      >
        <div class="photos__item--delete" @click="deleteImage(img.id)"></div>
        <img class="photos__item--img" :src="img.data" alt="" />
      </div>
      <div class="photos__add" @click="$refs.fileInput.click()">
        <input
          type="file"
          @change="onFileSelected"
          style="display: none"
          ref="fileInput"
        />
        <div class="add__item">
          <div class="add__item--img">
            <img src="../../../../assets/img/plus.svg" alt="" />
          </div>
          <p class="add__item--text">{{ $t('new_station_sidebar.add_photo') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_NEW_STATION_ACTION } from '@/store/constants'

export default {
  name: 'Photos',
  data: () => ({
    photos: []
  }),
  updated () {
    this.autoScroll()
  },
  methods: {
    onFileSelected (e) {
      if (e.target.files) {
        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0])
        reader.onloadend = (ev) => {
          this.photos.push({
            id: this.photos.length > 0
              ? this.photos[this.photos.length - 1].id + 1
              : 0,
            data: ev.target.result,
            name: e.target.files[0].name
          })

          this.updateStore()
        }
      }
    },
    updateStore () {
      this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
        updatedStationField: 'photos',
        values: this.photos
      })
    },
    deleteImage (id) {
      this.photos = this.photos.filter(photo => photo.id !== id)
      this.updateStore()
    },
    autoScroll () {
      const item = document.querySelector('.photos__item')
      const grid = document.querySelector('.photos__grid')
      const imgWidth = item ? item.clientWidth : 250
      grid.scrollLeft += imgWidth
    }
  }
}
</script>

<style scoped lang="scss">
$green: #5ab464;

.add-station__item.item__photos {
  position: relative;
}
.photos__heading {
  margin-bottom: 10px;
}
.photos__grid {
  display: flex;
  justify-content: space-between;
  min-height: 150px;
  overflow-y: auto;

  .photos__item {
    flex-basis: 250px;
    max-height: 150px;
    flex-shrink: 0;
    padding-right: 30px;
    position: relative;

    &:hover .photos__item--delete {
      display: block;
    }
    &--delete {
      position: absolute;
      top: 0;
      right: 15px;
      width: 10px;
      height: 10px;
      background-color: #545759;
      -webkit-mask: url(../../../../assets/img/close.svg) center center/contain no-repeat;
      display: none;
      cursor: pointer;
    }
    & img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.photos__add {
  flex-basis: 80px;
  flex-shrink: 0;
  cursor: pointer;

  .add__item {
    position: relative;
    top: calc(50% - 30px);

    &--img {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #e0e0e0;
      color: $green;
      width: 36px;
      height: 36px;
      margin: 0 auto 5px;
    }
    &--text {
      color: $green;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
    }
  }
}
</style>
