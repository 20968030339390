export const nearbyPlaces = [
  {
    id: 1,
    imgName: 'bank',
    text: 'Bank',
    text_r: 'Банк'
  }, {
    id: 2,
    imgName: 'beauty',
    text: 'Beauty salon',
    text_r: 'Салон красоты'
  }, {
    id: 3,
    imgName: 'car_rental',
    text: 'Car rental',
    text_r: 'Аренда авто'
  }, {
    id: 4,
    imgName: 'car_wash',
    text: 'Car wash',
    text_r: 'Автомойка'
  }, {
    id: 5,
    imgName: 'car_dealer',
    text: 'Cars dealer',
    text_r: 'Авто салон'
  }, {
    id: 6,
    imgName: 'cinema',
    text: 'Cinema',
    text_r: 'Кино'
  }, {
    id: 7,
    imgName: 'dining',
    text: 'Dining',
    text_r: 'Кафе'
  }, {
    id: 8,
    imgName: 'ev_parking',
    text: 'EV Parking',
    text_r: 'EV Парковка'
  }, {
    id: 9,
    imgName: 'gas',
    text: 'Gas',
    text_r: 'Заправка'
  }, {
    id: 10,
    imgName: 'grocery',
    text: 'Bank & ATM',
    text_r: 'Супермаркет'
  }, {
    id: 11,
    imgName: 'gym',
    text: 'Gym',
    text_r: 'Тренажерный зал'
  }, {
    id: 12,
    imgName: 'home_and_garden',
    text: 'Home & Garden',
    text_r: 'Дом и огород'
  }, {
    id: 13,
    imgName: 'hospital',
    text: 'Hospital & Clinic',
    text_r: 'Госпиталь'
  }, {
    id: 14,
    imgName: 'hotel',
    text: 'Hotel',
    text_r: 'Отель'
  }, {
    id: 15,
    imgName: 'lodging',
    text: 'Lodging',
    text_r: 'Жилье'
  }, {
    id: 16,
    imgName: 'park',
    text: 'Park',
    text_r: 'Парк'
  }, {
    id: 17,
    imgName: 'restroom',
    text: 'Restaurant',
    text_r: 'Рестораны'
  }, {
    id: 18,
    imgName: 'shopping',
    text: 'Shopping center',
    text_r: 'Покупки'
  }, {
    id: 19,
    imgName: 'valet_parking',
    text: 'Valet Parking',
    text_r: 'Парковка'
  }, {
    id: 20,
    imgName: 'wifi',
    text: 'WiFi',
    text_r: 'WiFi'
  }
]
