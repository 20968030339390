export default {
  header: {
    login: 'Войти',
    about_company: 'О компании',
    about_service: 'Об услуге',
    buy_station: 'Купить зарядную станцию'
  },
  auth_popup: {
    authorization: 'Авторизация',
    reset_password_label: 'Сброс пароля',
    reset_password: 'Сбросить пароль',
    register: 'Зарегистрироваться',
    registration: 'Регистрирация',
    our_application: 'Наше приложение',
    agree_to_terms: 'Согласен с <a target="_blank" href="{ url }" class="rools_link">правилами</a>',
    form: {
      login: 'Email или номер телефона',
      email: 'Email',
      password: 'Пароль',
      repeat_password: 'Повторите пароль',
      name: 'Имя',
      second_name: 'Фамилия',
      phone_number: 'Номер телефона',
      vin: 'VIN',
      plate: 'Plate',
      auto_vendor: 'Auto vendor',
      auto_model: 'Auto model',
      secret_word: 'Secret word'
    }
  },
  language: {
    name: 'Язык'
  },
  profile: {
    profile_title: 'Профиль',
    hello_word: 'Привествую, ',
    balance: 'Баланс: '
  },
  main_menu: {
    title: 'Меню',
    main_page: 'Главная',
    charge_page: 'Зарядка',
    cars_page: 'Мои авто',
    statistic_page: 'Статистика',
    sales_page: 'Продажи',
    my_stations_page: 'Мои станции',
    comments_page: 'Комментарии',
    new_stations_page: 'Новые станции',
    control_room_page: 'Диспетчерская'
  },
  mobile_modal: {
    app_is_better: 'С приложением удобнее',
    work_from_phone: 'Качайте и работайте с телефона!'
  },
  station_sidebar: {
    go_to_site: 'Перейти на сайт',
    open: 'Открыто',
    near: 'Рядом',
    schedule: {
      all_day: 'Круглосуточно'
    },
    comments: {
      month_ago: '1 месяц назад',
      year_ago: 'год назад',
      years_ago: 'год назад',
      'two-four_months_ago': 'месяца назад',
      'five-twelve_months_ago': 'месяцев назад',
      today: 'сегодня',
      days_ago: 'дней назад',
      cancel_button: 'Отменить',
      send_button: 'Отправить'
    },
    rates: {
      time: 'ч',
      parking: 'мин',
      kWh: 'кВт*ч'
    }
  },
  new_station_sidebar: {
    head: {
      title: 'Добавление зарядной станции',
      description: 'При добавлении новой станции в систему вы можете внести основную информацю о станции (название, адрес, коннектора) дополнительные данные (графк работы, описание станции и график ее работы) а так же фотографии станции.'
    },
    schedule: {
      work_time: 'Время работы',
      open_full_time: 'Открыто: Круглосуточно',
      open_from: 'Открыто: c',
      open_to: 'до',
      closed: 'Закрыто'
    },
    edit: 'Редактировать',
    nearby_places: 'Ближайшие места',
    connectors_title: 'Коннекторы',
    add_connector: 'Добавить <br /> коннектор',
    photos_title: 'Фотографии',
    add_photo: 'Добавить фото',
    description: {
      title: 'Описание',
      input_name: 'Имя станции',
      input_name_error: 'Введите имя',
      input_address: 'Адрес станции',
      input_site: 'Сайт станции(компании)',
      input_site_error: 'Некоректный адрес',
      input_description: 'Описание'
    }
  },
  filters: {
    kW: 'кВт',
    sessions: 'Сессий',
    charged: 'заряжено'
  },
  search: {
    title: 'Поиск',
    search_field_placeholder: 'Напишите название зарядной станции'
  },
  statuses: {
    available: 'Доступен',
    preparing: 'Заряжает',
    charging: 'Заряжает',
    finishing: 'Заряжает',
    suspendedEVSE: 'Заряжает',
    suspendedEV: 'Заряжает',
    occupied: 'Заряжает',
    unavailable: 'Недоступен',
    faulted: 'Неисправен',
    unknown: 'Неизвестен'
  },
  edit_station_info: {
    edit_station_name: 'Название станции',
    edit_station_connectors_name: 'Коннекторы',
    edit_address_station: 'Адрес станции',
    edit_station_description: 'Описание',
    edit_site_station: 'Сайт станции(компании)',
    edit_button: 'Редактировать',
    incorrect_name: 'Некорректное имя',
    incorrect_address: 'Некорректное адресс',
    edit_photo: 'Фото',
    save_button: 'Сохранить'
  },
  edit_stations_images: {
    edit_photo: 'Добавить фото'
  },
  edit_station_connectors: {
    edit_connector: 'Добавить коннектор'
  },
  nearbyPlaces: {
    bank: 'Банк',
    beauty: 'Красота',
    car_rental: 'Прокат авто',
    car_wash: 'Мойка авто',
    car_dealer: 'Продажа авто',
    cinema: 'Кино',
    dining: 'Столовая',
    ev_parking: 'Парковка',
    gas: 'Газ',
    grocery: 'Бакалея',
    gym: 'Спортзал',
    home_and_garden: 'Дом и сад',
    hospital: 'Больница',
    hotel: 'Отель',
    lodging: 'Жилье',
    park: 'Парк',
    restroom: 'Туалет',
    shopping: 'Магазины',
    valet_parking: 'Услуги парковщика',
    wifi: 'WiFi'
  }
}
