<template>
  <div>
    <div class="station-info__places">
      <div class="station-info__places--item">
        <p class="station-info__places--text">{{ $t('new_station_sidebar.nearby_places') }}:</p>
      </div>
      <div class="station-info__places--item" @click="showNearbyPlacesModal">
        <a href="#" class="station-info__places--edit">{{ $t('new_station_sidebar.edit') }}</a>
      </div>
    </div>
    <div class="station-info__icons">
      <div
        class="station-info__icons--icon"
        v-for="placeIdx in newStation.nearbyPlaces"
        :key="placeIdx"
      >
        <img
          :src="`nearby_places/${places[placeIdx].imgName}.svg`"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SET_MODAL_VISIBILITY_MUTATION } from '@/store/constants'
import { nearbyPlaces } from '@/data/nearbyPlaces'

export default {
  name: 'NearbyPlaces',
  methods: {
    showNearbyPlacesModal () {
      this.$store.commit(SET_MODAL_VISIBILITY_MUTATION, {
        modalName: 'nearbyPlacesModalVisibility',
        flag: true
      })
    }
  },
  computed: {
    ...mapState(['newStation']),
    places () {
      return nearbyPlaces
    }
  }
}
</script>

<style scoped lang="scss">
$green: #5ab464;

.station-info__places {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  &--edit {
    color: $green;
    font-size: 14px;
  }
  &--text {
    font-size: 14px;
  }
}
.station-info__icons {
  display: flex;
  min-height: 20px;

  &--icon {
    margin-right: 5px;

    img {
      height: 20px;
    }
  }
}
</style>
