<template>
  <div class="station-info__schedule">
    <div class="station-info__schedule--item">
      <span class="station-info__schedule--icon">
        <img src="../../../../assets/img/clock.svg" alt=""/>
      </span>
      <span class="station-info__schedule--text">{{ workTime }}</span>
    </div>
    <div class="station-info__schedule--item" @click="showScheduleModal">
      <a href="#" class="station-info__schedule--edit">{{ $t('new_station_sidebar.edit') }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SET_MODAL_VISIBILITY_MUTATION } from '@/store/constants'

export default {
  name: 'Schedule',
  methods: {
    showScheduleModal () {
      this.$store.commit(SET_MODAL_VISIBILITY_MUTATION, {
        modalName: 'scheduleModalVisibility',
        flag: true
      })
    }
  },
  computed: {
    ...mapState(['newStation']),
    workTime () {
      if (!this.newStation.schedule.length) {
        return this.$t('new_station_sidebar.schedule.work_time')
      }

      const dayNumber = new Date().getDay() - 1
      const currentDay = this.newStation.schedule[dayNumber]
      const workingHours = currentDay.workingHours

      if (currentDay.isWorking) {
        return workingHours[0].from === 'fullTime'
          ? this.$t('new_station_sidebar.schedule.open_full_time')
          : `${this.$t('new_station_sidebar.schedule.open_from')} ${workingHours[0].from} ${this.$t('new_station_sidebar.schedule.open_to')} ${workingHours[workingHours.length - 1].to}`
      } else {
        return this.$t('new_station_sidebar.schedule.closed')
      }
    }
  }
}
</script>

<style scoped lang="scss">
$green: #5ab464;

.station-info__schedule {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &--item {
    display: flex;
  }

  &--icon {
    margin-right: 5px;

    img {
      height: 20px;
    }
  }

  &--edit {
    color: $green;
    font-size: 14px;
  }

  &--text {
    font-size: 14px;
  }
}

.station-info__icons {
  display: flex;

  &--icon {
    margin-right: 5px;
  }
}
</style>
