<template>
  <div>
    <div class="title">
      {{ connectorName }} <br>
      <span class="connector_power" :class="{'not-included': !connector.max_output_A }">{{ connector.max_output_A }}A</span>
    </div>
    <div class="available img">
      <div class="img-container" :style="imgStyles"></div>
    </div>
    <div class="status" :style="{ color: statusColor }">
      {{ statusName }}
    </div>
    <div class="att_kwh d_flex" v-if="ratePerkWh">
      <img src="../../../../assets/img/att_kwh.svg" alt="att_kwh">
      <span>{{ ratePerkWh }}</span>
    </div>
    <div class="att_h d_flex" v-if="ratePerHour">
      <img src="../../../../assets/img/time.svg" alt="time">
      <span>{{ ratePerHour }}</span>
    </div>
    <div class="att_h d_flex" v-if="parkingRate">
      <img src="../../../../assets/img/parking.svg" alt="time">
      <span>{{ parkingRate }}</span>
    </div>
  </div>
</template>

<script>
import {
  connectorStatuses,
  connectorTypes,
  currencySymbols,
  connectorTypeColors
} from '@/data'

const getCurrencySymbol = (rate) => currencySymbols[rate.currencyCode] || rate.currencyCode || currencySymbols.UAH

export default {
  name: 'Connector',
  props: {
    connector: Object
  },
  computed: {
    ratePerHour () {
      if (!this.connector.rates) {
        return null
      }
      const rate = this.connector.rates.find(({ type }) => type === 'time')
      if (!rate || typeof rate.price !== 'number') {
        return null
      }

      const currencySymbol = getCurrencySymbol(rate)
      return `${rate.price.toFixed(1)} ${currencySymbol}/${this.$t(`station_sidebar.rates.${rate.type}`)}`
    },
    ratePerkWh () {
      if (!this.connector.rates) {
        return null
      }
      const rate = this.connector.rates.find(({ type }) => type === 'kWh')

      if (!rate || typeof rate.price !== 'number') {
        return null
      }

      const currencySymbol = getCurrencySymbol(rate)
      return `${rate.price.toFixed(1)} ${currencySymbol}/${this.$t(`station_sidebar.rates.${rate.type}`)}`
    },
    parkingRate () {
      if (!this.connector.rates) {
        return null
      }
      const rate = this.connector.rates.find(({ type }) => type === 'parking')
      if (!rate || typeof rate.price !== 'number') {
        return null
      }

      const currencySymbol = getCurrencySymbol(rate)
      return `${rate.price.toFixed(1)} ${currencySymbol}/${this.$t(`station_sidebar.rates.${rate.type}`)}`
    },
    connectorName () {
      return connectorTypes[this.connector.type].name || 'Unknown type'
    },
    statusColor () {
      return connectorStatuses[1].color
      // return connectorStatuses[this.connector.status].color || connectorStatuses.Charging.color
    },
    statusName () {
      return this.$t(`statuses.${connectorStatuses[1].text}`)
      // return this.$t(`statuses.${connectorStatuses[this.connector.status].text || connectorStatuses.Charging.text}`)
    },
    imgStyles () {
      let color
      if (this.connector.status && this.connector.status === 'Available') {
        color = (connectorTypes[this.connector.type].fast) ? connectorTypeColors.fast : connectorTypeColors.regular
      }
      return {
        mask: `url(/v2/connectors_and_sockets/${connectorTypes[this.connector.type].imgName}) no-repeat center / contain`,
        '-webkit-mask': `url(/v2/connectors_and_sockets/${connectorTypes[this.connector.type].imgName}) no-repeat center / contain`,
        background: color || this.statusColor
      }
    }
  }
}
</script>

<style scoped lang="scss">
.img-container {
  height: 50px;
}
.connector_power.not-included {
  visibility: hidden;
}
</style>
