<template>
  <div class="slider">
    <img class="slider_item"
         v-for="(image, i) in images"
         @click="selectImg(i)"
         :key="image.id"
         :src="image.previewUrl">
    <vue-easy-lightbox
      @hide="visibleLightBox = false"
      escDisabled
      moveDisabled
      :visible="visibleLightBox"
      :imgs="imgUrls"
      :index="activeIndex" />
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'StationImages',
  components: {
    VueEasyLightbox
  },
  props: ['images'],
  data: () => ({
    selectedImg: null,
    visibleLightBox: false,
    activeIndex: 0
  }),
  computed: {
    imgUrls () {
      return this.images.map(img => img.fullImgUrl)
    }
  },
  methods: {
    selectImg (i) {
      this.visibleLightBox = true
      this.activeIndex = i
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  margin-top: 15px;
  display: flex;
  overflow-x: scroll;
  width: auto;

  &_item {
    margin-right: 6px;
    position: relative;
    height: 156px;
    border-radius: 10px;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
