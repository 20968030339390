import { connectorStatuses, connectorTypeColors, connectorTypes } from '@/data'
import connectorMarkers from '@/data/connectorMarkers'

export const getMarkerData = (connectors) => {
  const regular = {
    count: 0,
    unavailable: 0,
    charging: 0
  }
  const fast = { ...regular }
  const str = connectorMarkers[connectors.length]

  let connectorImg = str
    .replace('*svgParams*', 'version="1.1" xmlns="http://www.w3.org/2000/svg" width="31px" height="36px" class="marker"')
  connectors.forEach(({ status, type }, i) => {
    if (connectorTypes[type]) {
      let color

      if (status === 'unknown') {
        color = connectorTypeColors.unknown
        regular.count++
      } else {
        if (connectorTypes[type].fast) {
          color = connectorTypeColors.fast
          fast.count++
        } else {
          color = connectorTypeColors.regular
          regular.count++
        }
      }

      connectorImg = connectorImg.replace(`*fill${i + 1}*`, `fill="${color}"`)
    } else {
      connectorImg = connectorImg.replace(`*fill${i + 1}*`, `fill="${connectorTypeColors.unknown}"`)
    }

    if (connectorStatuses[status]) {
      connectorImg = connectorImg.replace(
        `*stroke${i + 1}*`,
        `fill="${connectorStatuses[status].markerColor || connectorStatuses[status].color}"`
      )
    } else {
      connectorImg = connectorImg.replace(`*stroke${i + 1}*`, `fill="${connectorStatuses.charging.color}"`)
    }
  })

  return {
    connectorImg,
    fast,
    regular
  }
}
