<template>
  <div class="add-station__item item__connectors">
    <h4 class="connectors__heading item__heading">{{ $t('new_station_sidebar.connectors_title') }}</h4>
    <div class="connectors__grid">
      <EditConnector
        v-for="(connector, i) in connectors"
        :connector="connector"
        :deleteConnector="deleteConnector"
        :key="i"
      />
      <div class="connectors__add" @click="showConnectorsModal">
        <div
          :class="['add__item', {
            unvalidated: showRequiredFields && !connectors.length
          }]"
        >
          <div class="add__item--img">
            <div class="img"></div>
<!--            <img src="../../../../assets/img/plus.svg" alt="" />-->
          </div>
          <p class="add__item--text" v-html="$t('new_station_sidebar.add_connector')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EditConnector from './EditConnector'
import {
  SET_MODAL_VISIBILITY_MUTATION,
  UPDATE_NEW_STATION_ACTION
} from '@/store/constants'
import { connectorTypes } from '@/data'

export default {
  name: 'EditConnectors',
  props: {
    showRequiredFields: Boolean
  },
  components: {
    EditConnector
  },
  methods: {
    showConnectorsModal () {
      this.$store.commit(SET_MODAL_VISIBILITY_MUTATION, {
        modalName: 'chooseConnectorModalVisibility',
        flag: true
      })
    },
    deleteConnector (type) {
      const connectors = this.newStation.connectors.filter(
        (connector) => connector.type !== type
      )

      this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
        updatedStationField: 'connectors',
        values: connectors
      })
    }
  },
  computed: {
    ...mapState(['newStation']),
    connectors () {
      return this.newStation.connectors.map(({ type }, i) => ({
        id: i,
        connectorName: connectorTypes[type].name,
        imgName: connectorTypes[type].imgName,
        type
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
$green: #5ab464;
$grey: #545759;

.connectors__item {
  padding-bottom: 25px;
  position: relative;
}
.connectors__heading {
  margin-bottom: 5px;
}
.connectors__grid {
  display: flex;
  flex-wrap: wrap;
}

.connectors__add {
  flex-basis: 22%;
  cursor: pointer;
  min-height: 180px;

  .add__item {
    position: relative;
    top: calc(50% - 30px);

    &.unvalidated {
      .add__item--text {
        color: #EB5757;
      }
      .add__item--img .img {
        background-color: #EB5757;
      }
    }

    &--img {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #e0e0e0;
      width: 36px;
      height: 36px;
      margin: 0 auto 5px;

      & .img {
        -webkit-mask: url(../../../../assets/img/plus.svg) center
        center / contain no-repeat;
        background-color: $green;
        width: 20px;
        height: 20px;
      }
    }
    &--text {
      color: $green;
      font-size: 10px;
      line-height: 10px;
      text-align: center;
    }
  }
}
</style>
