export default {
  header: {
    login: 'Conecte-se',
    about_company: 'Sobre a empresa',
    about_service: 'ASobre o serviço',
    buy_station: 'Comprar estação de carga'
  },
  auth_popup: {
    authorization: 'Autorização',
    reset_password_label: 'Redefinir senha',
    reset_password: 'Redefinir senha',
    register: 'Registre-se',
    registration: 'Registro',
    our_application: 'Nosso aplicativo',
    agree_to_terms: 'Concordo com os <a target="_blank" href="{url}" class="rools_link"> termos</a>',
    form: {
      login: 'Email ou número de telefone',
      email: 'Email',
      password: 'Senha',
      repeat_password: 'Repete a senha',
      name: 'Nome',
      second_name: 'Segundo nome',
      phone_number: 'Phone number',
      vin: 'VIN',
      plate: 'Plate',
      auto_vendor: 'Auto vendor',
      auto_model: 'Auto model',
      secret_word: 'Secret word'
    }
  },
  language: {
    name: 'Língua'
  },
  profile: {
    profile_title: 'Perfil',
    hello_word: 'Bem-vindo, ',
    balance: 'Saldo: '
  },
  main_menu: {
    title: 'Menu',
    main_page: 'Pagina inicial',
    charge_page: 'Carregando',
    cars_page: 'Meus carros',
    statistic_page: 'Estatísticas',
    sales_page: 'Vendas',
    my_stations_page: 'Minhas estações',
    comments_page: 'Comentários',
    new_stations_page: 'Novas estações',
    control_room_page: 'Sala de controle'
  },
  mobile_modal: {
    app_is_better: 'O aplicativo é mais conveniente',
    work_from_phone: 'Baixe e trabalhe do seu telefone'
  },
  station_sidebar: {
    go_to_site: 'Ir para o site',
    open: 'Abrir',
    near: 'Perto',
    schedule: {
      all_day: '24/7'
    },
    comments: {
      month_ago: 'Um mês atrás',
      year_ago: 'ano atrás',
      years_ago: 'anos atrás',
      'two-four_months_ago': 'meses atrás',
      'five-twelve_months_ago': 'meses atrás',
      today: 'hoje',
      days_ago: 'dias atrás',
      cancel_button: 'Cancelar',
      send_button: 'Mandar'
    },
    rates: {
      time: 'h',
      parking: 'min',
      kWh: 'kW*h'
    }
  },
  filters: {
    kW: 'kW',
    sessions: 'Sessões',
    charged: 'Cobrado'
  },
  search: {
    title: 'Procurar',
    search_field_placeholder: 'Nome da estação de entrada'
  },
  statuses: {
    available: 'Disponível',
    preparing: 'Carregando',
    charging: 'Carregando',
    finishing: 'Carregando',
    suspendedEVSE: 'Carregando',
    suspendedEV: 'Carregando',
    occupied: 'Carregando',
    unavailable: 'Indisponível',
    faulted: 'Com defeito',
    unknown: 'Unknown'
  },
  edit_station_info: {
    edit_station_name: 'Nome da estação',
    edit_station_connectors_name: 'Conectores',
    edit_address_station: 'Endereço da estação',
    edit_station_description: 'Descrição',
    edit_site_station: 'Site da estação (empresa)',
    edit_button: 'Editar',
    incorrect_name: 'Nome incorreto',
    incorrect_address: 'Endereço incorreto',
    edit_photo: 'Fotos',
    save_button: 'Salvar'
  },
  edit_stations_images: {
    edit_photo: 'Adicionar foto'
  },
  edit_station_connectors: {
    edit_connector: 'Adicionar conector'
  }
}
