export const connectorStatuses = {
  1: {
    color: '#31B447',
    markerColor: 'transparent',
    text: 'available'
  },
  2: {
    color: '#0056F2',
    text: 'charging'
  },
  3: {
    color: '#0056F2',
    text: 'charging'
  },
  4: {
    color: '#0056F2',
    text: 'charging'
  },
  5: {
    color: '#0056F2',
    text: 'charging'
  },
  6: {
    color: '#0056F2',
    text: 'charging'
  },
  7: {
    color: '#0056F2',
    text: 'charging'
  },
  8: {
    color: '#DA291C',
    text: 'unavailable'
  },
  9: {
    color: '#DA291C',
    text: 'faulted'
  },
  10: {
    color: 'transparent',
    text: 'unknown'
  }
}
