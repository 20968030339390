export const createMarker = (connectorImg, coords, connectorsData) => {
  const marker = new window.google.maps.Marker({
    icon: {
      url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(connectorImg),
      scaledSize: new window.google.maps.Size(31, 36)
    },
    position: {
      lat: coords.lat,
      lng: coords.lon
    },
    optimized: true
  })

  marker.connectorsData = connectorsData

  return marker
}
