<template>
  <div id="main_wrapper" class="main_wrapper">
    <div id="wrapper" class="wrapper">
      <Header/>
      <router-view/>
      <Footer/>
    </div>
    <MobilePopup/>
    <MenuPopup/>
    <SearchStation/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import AuthPopup from '@/components/pages/home/AuthPopup'
import MenuPopup from '@/components/MenuPopup'
import SearchStation from '@/components/pages/home/SearchStation'
import MobilePopup from '@/components/MobilePopup'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    AuthPopup,
    MenuPopup,
    SearchStation,
    MobilePopup
  }
}
</script>

<style lang="scss">

</style>
