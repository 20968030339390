import * as d3 from 'd3'

export const createClusterChart = (data = [], size = 40) => {
  const radius = size * 0.65

  const arc = d3.arc()
    .outerRadius(radius - 8)
    .innerRadius(radius - 15)

  const pie = d3.pie()
    .sort(null)
    .value((d) => d.value)

  const div = document.createElement('div')
  const svg = d3.select(div).append('svg')
    .attr('width', size)
    .attr('height', size)
    .append('g')
    .attr('transform', 'translate(' + size / 2 + ',' + size / 2 + ')')

  // background
  svg.append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', (radius / 2) + 1)
    .attr('fill', '#fff')

  const g = svg.selectAll('.arc')
    .data(pie(data))
    .enter().append('g')

  // connectors
  g.append('path')
    .attr('d', arc)
    .attr('stroke', '#fff')
    .style('fill', (d) => d.data.color)

  // connectors count number
  if (data[0].value) {
    svg.append('text')
      .style('font-size', '11px')
      .style('font-family', 'Lato')
      .style('text-anchor', 'middle')
      .style('fill', data[0].color)
      .attr('y', '-3px')
      .text(data[0].value)

    svg.append('text')
      .style('font-size', '11px')
      .style('font-family', 'Lato')
      .style('fill', data[1].color)
      .style('text-anchor', 'middle')
      .attr('y', '11px')
      .text(data[1].value)
  } else {
    svg.append('text')
      .style('font-size', '12px')
      .style('font-family', 'Lato')
      .style('fill', data[1].color)
      .style('text-anchor', 'middle')
      .attr('y', '4px')
      .text(data[1].value)
  }

  return div.querySelector('svg')
}
