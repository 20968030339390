<template>
  <ModalWrapper
    :show-backdrop="$store.state.modals.scheduleModalVisibility"
    @closeModal="close"
  >
    <div class="choose-modal_wrap schedule_modal">
      <div class="header_modal">
        <div class="close" @click="close"></div>
        <h2 class="modal_title">График работы</h2>
      </div>
      <div class="modal_content">
        <ScheduleDay
          v-for="day in days"
          :day="day"
          :key="day.id"
        />
      </div>
      <div class="buttons_modal footer_modal">
        <button class="cancel_button" @click="close">Отменить</button>
        <button class="submitted_btn" @click="save">Готово</button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapState } from 'vuex'
import ScheduleDay from './ScheduleDay'
import ModalWrapper from '@/components/ModalWrapper'
import {
  SET_MODAL_VISIBILITY_MUTATION,
  UPDATE_NEW_STATION_ACTION
} from '@/store/constants'

export default {
  name: 'ScheduleModal',
  components: {
    ModalWrapper,
    ScheduleDay
  },
  beforeMount () {
    const workTime = this.newStation.schedule

    if (workTime.length < 7) {
      const dayNames = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье'
      ]

      this.days = dayNames.map((name, i) => ({
        id: i,
        name,
        isWorking: true,
        fullTime: false,
        workingHours: [{ from: 9, to: 21 }]
      }))
    } else {
      this.days = workTime
    }
  },
  data: () => ({
    days: []
  }),
  methods: {
    save () {
      this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
        updatedStationField: 'schedule',
        values: this.days
      })

      this.close()
    },
    close () {
      this.$store.commit(SET_MODAL_VISIBILITY_MUTATION, {
        modalName: 'scheduleModalVisibility',
        flag: false
      })
    }
  },
  computed: mapState(['newStation'])
}
</script>

<style scoped>
</style>
