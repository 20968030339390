const socketTypes = {
  10: {
    fast: false,
    name: 'Socket A',
    imgName: 'type_A.svg',
    order: 6
  },
  11: {
    fast: false,
    name: 'Socket B',
    imgName: 'type_B.svg',
    order: 6
  },
  12: {
    fast: false,
    name: 'Socket C',
    imgName: 'type_C.svg',
    order: 6
  },
  13: {
    fast: false,
    name: 'Socket D',
    imgName: 'type_D.svg',
    order: 6
  },
  14: {
    fast: false,
    name: 'Socket E',
    imgName: 'type_E.svg',
    order: 6
  },
  15: {
    fast: false,
    name: 'Socket F (220V)',
    shortName: 'Socket F',
    imgName: 'type_F.svg',
    order: 6
  },
  16: {
    fast: false,
    name: 'Socket G',
    imgName: 'type_G.svg',
    order: 6
  },
  17: {
    fast: false,
    name: 'Socket H',
    imgName: 'type_H.svg',
    order: 6
  },
  18: {
    fast: false,
    name: 'Socket I',
    imgName: 'type_I.svg',
    order: 6
  },
  19: {
    fast: false,
    name: 'Socket K',
    imgName: 'type_K.svg',
    order: 6
  },
  20: {
    fast: false,
    name: 'Socket L',
    imgName: 'type_L.svg',
    order: 6
  },
  21: {
    fast: false,
    name: 'Socket M',
    imgName: 'type_M.svg',
    order: 6
  },
  22: {
    fast: false,
    name: 'Socket N',
    imgName: 'type_N.svg',
    order: 6
  },
  23: {
    fast: false,
    name: 'Socket O',
    imgName: 'type_O.svg',
    order: 6
  }
}

export const connectorTypes = {
  1: {
    fast: true,
    name: 'CHAdeMO',
    imgName: 'chademo.svg',
    order: 1
  },
  2: {
    fast: true,
    name: 'CCS Combo 1',
    shortName: 'CCS 1',
    imgName: 'ccs_type1.svg',
    order: 3
  },
  3: {
    fast: true,
    name: 'CCS Combo 2',
    shortName: 'CCS 2',
    imgName: 'ccs_type2.svg',
    order: 3
  },
  4: {
    fast: true,
    name: 'CCS T1/T2',
    imgName: 'CCS.svg',
    order: 2
  },
  5: {
    fast: false,
    name: 'Type 1 (J1772)',
    shortName: 'Type 1',
    imgName: 'j-1772-01.svg',
    order: 5
  },
  6: {
    fast: false,
    name: 'Type 2 (1 phase)',
    imgName: 'type2_socket2-01.svg',
    order: 4
  },
  7: {
    fast: false,
    name: 'Type 2 (1 phase) Socket',
    imgName: 'type2_soket2-01.svg',
    order: 4
  },
  8: {
    fast: false,
    name: 'Type 2 (3 phase)',
    imgName: 'type2_soket2-02-01.svg',
    order: 4
  },
  9: {
    fast: false,
    name: 'Type 2 (3 phase) Socket',
    imgName: 'type2_soket2-03.svg',
    order: 4
  },
  24: {
    fast: false,
    name: 'Other',
    imgName: 'type2_soket2-03.svg',
    order: 4
  },
  ...socketTypes
}

export const dcTypesId = [1, 2, 3]
export const acTypesId = [5, 6, 8]
export const socketTypesId = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
