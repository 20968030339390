export default {
  stations: {},
  selectedStation: null,
  menuVisibility: false,
  searchFieldVisibility: false,
  authMenuVisibility: false,
  newStation: null,
  modals: {
    chooseConnectorModalVisibility: false,
    nearbyPlacesModalVisibility: false,
    scheduleModalVisibility: false
  }
}

export const globalState = {
  stationIds: new Set()
}
