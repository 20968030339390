<template>
  <footer id="footer" class="footer"></footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
