<template>
  <ul class="list d_flex">
    <li class="item"
        :id="'filter-' + connectorType.key"
        :class="{ show: activeFilterKey === connectorType.key }"
        v-for="connectorType in filters"
        @click="filter(connectorType)"
        :key="connectorType.key">
      <div class="inner d_flex">
        <div class="inner_img">
          <div class="img-container" :style="getStyles(connectorType)"></div>
        </div>
        <div class="table description">
          <div class="table_row">
            <div class="table_cell title">
              <strong>{{ connectorType.name }}</strong>
            </div>
            <div id="qty_type1" class="table_cell">
              <span>({{ connectorType.count }})</span>
            </div>
          </div>
          <div class="table_row">
            <div class="table_cell">
              {{ $t('filters.kW') }}, {{ $t('filters.charged') }}<br/>
              <span>{{ connectorType.kWh }}</span>
            </div>
            <div class="table_cell qty_cars">
              {{ $t('filters.sessions') }}<br/>
              <span>{{ connectorType.sessions }}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { connectorTypesInFilter as connectorsTypes, connectorTypeColors } from '@/data'
import { eventBus } from '@/eventBus'
import { FILTER_STATIONS_EVENT } from '@/constants'

export default {
  name: 'StationFilter',
  props: {
    idsByTypes: Object
  },
  data: () => ({
    filters: connectorsTypes.slice(),
    activeFilterKey: null
  }),
  watch: {
    idsByTypes (val) {
      if (typeof val === 'object' && Object.keys(val).length) {
        this.setConnectorsCount()
      }
    }
  },
  methods: {
    setConnectorsCount () {
      this.filters.forEach((filter) => {
        filter.connectorTypes.forEach((type) => {
          if (!this.idsByTypes.hasOwnProperty(type)) {
            return
          }
          if (!Object.keys(this.idsByTypes[type]).length) {
            return
          }
          filter.count += Object.keys(this.idsByTypes[type]).length
        })
      })
    },
    getStyles (connectorType) {
      return {
        mask: `url(/v2/connectors_and_sockets/${connectorType.img}) no-repeat center / contain`,
        '-webkit-mask': `url(/v2/connectors_and_sockets/${connectorType.img}) no-repeat center / contain`,
        background: (connectorType.fast) ? connectorTypeColors.fast : connectorTypeColors.regular
      }
    },
    filter (connectorType) {
      if (this.activeFilterKey === connectorType.key) {
        this.activeFilterKey = null
        eventBus.$emit(FILTER_STATIONS_EVENT, null)
      } else {
        this.activeFilterKey = connectorType.key
        eventBus.$emit(FILTER_STATIONS_EVENT, connectorType.connectorTypes)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.list {
  width: 76vw;
  margin: 0 auto;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
  height: auto;
  position: initial;
  z-index: 0;
  @media (min-width: 1200px) {
    position: relative;
    max-width: 1200px;
  }

  @media (min-width: 1024px) {
    overflow: visible;
    padding-top: 0;
  }

  .item {
    transition: all 200ms ease-in-out;
    cursor: pointer;
    padding: 0 4px;

    @media (max-width: 1200px) {
      flex-wrap: nowrap;
      flex: none;
      width: 25%;
    }
    @media (max-width: 767px) {
      width: 25%;
    }

    @media(min-width: 1190px) {
      flex: none;
    }

    .title {
      height: auto;
      margin-top: 3px;
    }

    .inner {
      background: #fff;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 16px 16px 0 0;
      transition: all 200ms ease-in-out;
      padding: 10px 0;
      height: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      @media(min-width: 1024px) {
        padding: 5px;
      }
      @media (max-width: 1023px) {
        display: block;
      }

      &_img {
        margin-bottom: 5px;
        margin-right: 5px;
        width: auto;
        flex: 1 0 auto;

        .img-container {
          height: 40px;
        }

        @media(min-width: 1200px) {
          width: 60px;
        }
        @media(min-width: 1360px) {
          max-width: none;
        }
        @media (max-width: 1023px) {
          margin: 0 auto;
          img {
            max-height: 40px;
            width: 60px;
          }

        }
      }
    }

    &:hover > .inner {
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.5);
    }
  }
}

.description {
  color: #333;
  //text-align: center;
  //width: 100%;
  @media (min-width: 1190px) {
    text-align: left;
  }
  @media (max-width: 1023px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .table_cell {
    padding: 2px 1px;
    font-size: 10px;
    transition: all 200ms ease-in-out;
    display: table-cell;
    text-align: center;
    @media (min-width: 575px) {
      font-size: 14px;
    }
    @media(min-width: 1360px) {
      font-size: 14px;
    }
    @media(min-width: 1560px) {
      font-size: 14px;
    }
    @media (max-width: 1023px) {
      display: block;
    }

    span {
      color: #828282;
      font-size: 11px;
      @media (min-width: 425px) {
        font-size: 14px;
      }
    }
  }

  .title {
    font-weight: bold;
    letter-spacing: -0.03em;
    color: #333;
    font-size: 10px;
    text-align: center;
    transition: all 200ms ease-in-out;
    line-height: 1.1;
    @media (min-width: 575px) {
      font-size: 14px;
    }

  }
}

.list .item {
  position: absolute;
  z-index: 3;
  height: 200px;

  @media (max-width: 1023px) {
    bottom: -47px;
    &.show {
      bottom: 45px;
    }
  }

  @media (max-width: 920px) {
    bottom: -50px;
    &.show {
      bottom: 50px;
    }
  }

  @media (max-width: 518px) {
    bottom: -55px;
    &.show {
      bottom: 0;
    }
  }

  @media (max-width: 400px) {
    bottom: 10px;

    .title {
      height: 25px;
    }
    &.show {
      //bottom: 100px;
    }
  }

  @media (min-width: 1024px) {
    height: 90px;
    bottom: -10px;
    //position: static;
    .inner {
      padding-top: 5px;
    }
    &.show {
      height: 100px;
      bottom: 0;
      .inner {
        padding-top: 10px;
      }
    }
  }
}

#filter-1 {
  left: 0;
  @media (max-width: 1023px) {
    left: 0;
  }
}
#filter-2 {
  left: 25%;
  @media (max-width: 1023px) {
    left: 25%;
  }

}
#filter-3 {
  left: 50%;
  @media (max-width: 1023px) {
    left: 50%;
  }

}
#filter-4 {
  left: 75%;
  @media (max-width: 1023px) {
    left: 75%;
  }

}
#filter-5 {
  left: 80%;
  @media (max-width: 1023px) {
    left: 80%;
  }
}
</style>
