<template>
  <div>
    <div class="modal">
      <slot/>
    </div>
    <div v-show="showBackdrop"
         @click="$emit('closeModal')"
         class="modal-backdrop"></div>
  </div>
</template>

<script>
export default {
  name: 'ModalWrapper',
  props: {
    showBackdrop: Boolean
  }
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,.3);
  transition: background-color .2s;
  z-index: 3;
}
</style>
