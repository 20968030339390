<template>
  <div class="comments">
    <div class="add_comment">
      <input type="text" id="comment text" placeholder="Оставьте комментарий">
      <div class="add_comment_buttons">
        <div class="rate">
          <input type="radio" id="star5" name="rate" value="5"/>
          <label for="star5" title="text">5 stars</label>
          <input type="radio" id="star4" name="rate" value="4"/>
          <label for="star4" title="text">4 stars</label>
          <input type="radio" id="star3" name="rate" value="3"/>
          <label for="star3" title="text">3 stars</label>
          <input type="radio" id="star2" name="rate" value="2"/>
          <label for="star2" title="text">2 stars</label>
          <input type="radio" id="star1" name="rate" value="1"/>
          <label for="star1" title="text">1 star</label>
        </div>
        <div class="buttons">
          <button class="cancel_button">{{ $t('station_sidebar.comments.cancel_button')}}</button>
          <button class="send_button">{{ $t('station_sidebar.comments.send_button')}}</button>
        </div>
      </div>
    </div>
    <div class="left_comments">
      <div class="left_comment_item" v-for="comment in comments" :key="comment.id">
        <div class="d_flex">
          <div class="user_name">{{ comment.authorName }}</div>
          <div class="rate">
            <span v-for="i in 5" :key="i"></span>
          </div>
        </div>
        <div class="left_text">
          <p>{{ comment.text }}</p>
        </div>
        <div class="d_flex">
          <div class="left_date">{{ getDate(comment.date) }}</div>
          <div class="comments_like d_flex">
            <div class="like">
              <span class="img"></span>
              <span>251</span></div>
            <div class="dislike">
              <span class="img"></span>
              <span>12</span></div>
          </div>
        </div>

        <div class="delimeter"></div>
      </div>
    </div>
  </div>
</template>

<script>
const oneDay = 24 * 60 * 60 * 1000

export default {
  name: 'Comments',
  props: {
    comments: Array
  },
  methods: {
    getDate (date) {
      date = new Date(date)
      const today = new Date()
      const diff = Math.round(Math.abs((today - date)) / oneDay)

      if (diff > 365) {
        const years = Math.floor(diff / 365)

        if (years === 1) {
          return `${years} ${this.$t('station_sidebar.comments.year_ago')}`
        } else {
          return `${years} ${this.$t('station_sidebar.comments.years_ago')}`
        }
      }

      if (diff > 30) {
        const mounths = Math.floor(diff / 30)
        if (mounths === 1) {
          return this.$t('station_sidebar.comments.month_ago')
        } else if (mounths < 5) {
          return `${mounths} ${this.$t('station_sidebar.comments.two-four_months_ago')}`
        } else {
          return `${mounths} ${this.$t('station_sidebar.comments.five-twelve_months_ago')}`
        }
      }

      if (diff === 0) {
        return this.$t('station_sidebar.comments.today')
      }

      return `${diff} ${this.$t('station_sidebar.comments.days_ago')}`
    }
  }
}
</script>

<style scoped lang="scss">

.comments {
  margin-top: 10px;
  padding-top: 15px;

  .comment_leave {
    &.d_flex {
      justify-content: flex-start;
    }
    .photo_text {
      width: 100%;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
    .leave_photo {
      width: 50px;
      height: 50px;
      background-color: #5DB461;
      border-radius: 5px;
    }
    input::placeholder {
      color: rgba(84, 87, 89, 0.5);
      font-weight: 700;
    }
    input::-moz-placeholder {
      font-weight: 700;
      color: rgba(84, 87, 89, 0.5);
    }
    .leave_text {
      width: 100%;
      border-bottom: 1px solid rgba(84, 87, 89, 0.5);
      border-radius: 0;
      margin-left: 5px;
      padding: 0;
      font-size: 12px;
      color: rgba(84, 87, 89, 0.5);
      background-color: #f2f2f2;
      margin-bottom: 20px;
    }
    .form_buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      @media (max-width:424px) {
        justify-content: center;
      }
      .btn_reset {
        border: 1px solid #31B447;
        background-color: #fff;
        color: #31B447;
        font-weight: 800;
        font-size: 12px;
        margin-right: 7px;
        width: 100%;
        max-width: 155px;
        @media (max-width: 424px) {
          font-weight: 300;
        }
      }
      .btn_submit {
        border: 1px solid #31B447;
        font-weight: 800;
        font-size: 12px;
        width: 100%;
        max-width: 155px;
        @media (max-width: 424px) {
          font-weight: 300;
        }
      }
    }
  }
  .left_comments {
    padding-bottom: 20px;
    margin-top: 20px;
   .left_comment_item {
     .d_flex {
       align-items: center;
       .rate {
         height: auto;
       }
       .like,
       .dislike {
         display: flex;
         align-items: center;
         cursor: pointer;
         .img {
           -webkit-mask: url(../../../../assets/img/like.svg) center center / contain no-repeat;
           background: #BDBDBD;
           height: 14px;
           width: 16px;
           background-size: cover;
           display: inline-block;
           margin-right: 10px;
         }
         span {
           color: #BDBDBD
         }
         &:hover {
           .img {
             background-color: #5AB464;
           }
           span {
             color: #5AB464;
           }
         }
         &.active {
           .img {
             background-color: #5AB464;
           }
           span {
             color: #5AB464;
           }
         }
       }
       .dislike {
         margin-left: 25px;
         .img {
           transform: rotate(180deg);
         }
       }
     }
     .user_photo {
       width: 50px;
       max-width: 50px;
       height: 50px;
       background-color: #5DB461;
       border-radius: 5px;
       margin-right: 5px;
     }
     .user_name {
       font-weight: 900;
       color: #545759;
       width: 65%;
     }
     .left_text {
       margin-top: 20px;
       margin-bottom: 20px;
       color: #545759;
       p {
         line-height: 20px;
       }
     }
     .left_date {
       font-size: 14px;
       color: rgba(189, 189, 189, 1);
     }

     .delimeter {
       height: 1px;
       border-top: 1px solid #dcd9d9;
       margin: 15px 0 15px 0;
     }

     &:last-child {
       .delimeter {
         display: none;
       }
     }
   }
  }
}
</style>
