<template>
  <ul class="d_flex">
    <li v-for="connector in sortedConnectors" :key="connector.id">
      <Connector :connector="connector"/>
    </li>
  </ul>
</template>

<script>
import { connectorTypes } from '@/data'
import Connector from '@/components/pages/home/station-info/Connector'

export default {
  name: 'Connectors',
  components: { Connector },
  props: {
    connectors: Array
  },
  computed: {
    sortedConnectors () {
      return this.connectors.slice().sort((a, b) => {
        const typeA = (connectorTypes[a.type]) ? connectorTypes[a.type].order : 10
        const typeB = (connectorTypes[b.type]) ? connectorTypes[b.type].order : 10

        return typeA - typeB
      })
    }
  }
}
</script>

<style scoped>

</style>
