import {
  GET_STATION_ACTION,
  GET_STATIONS_ACTION,
  GET_SEARCHED_STATIONS_ACTION,
  SEND_STATION_ACTION,
  SET_AUTH_MENU_VISIBILITY_MUTATION,
  SET_MENU_VISIBILITY_MUTATION,
  SET_NEW_STATION_MUTATION,
  SET_SELECTED_STATION_MUTATION,
  ADD_NEW_STATION_ACTION,
  UPDATE_NEW_STATION_ACTION,
  TOGGLE_AUTH_MENU_ACTION,
  TOGGLE_MENU_ACTION
} from '@/store/constants'
import api from '@/api'
import { globalState } from '@/store/state'

export default {
  async [GET_STATIONS_ACTION] ({ commit }, params) {
    try {
      const response = await api.getStationGeo(params)
      const newStations = []

      response.body.forEach((station) => {
        if (!station.conn.length) {
          return
        }

        if (globalState.stationIds.has(station.id)) {
          return
        }
        globalState.stationIds.add(station.id)
        newStations.push(station)
      })

      return newStations
    } catch (err) {
      console.error(err)
    }
  },
  async [GET_SEARCHED_STATIONS_ACTION] ({ commit }, params) {
    try {
      const response = await api.getStationsByText(params)

      return response.body.map(station => station)
    } catch (err) {
      console.error(err)
    }
  },
  async [GET_STATION_ACTION] ({ commit }, id) {
    try {
      const response = await api.getStation(id)
      commit(SET_SELECTED_STATION_MUTATION, response.body)
    } catch (err) {
      console.error(err)
    }
  },
  async [TOGGLE_MENU_ACTION] ({ state, commit }) {
    if (state.menuVisibility) {
      commit(SET_MENU_VISIBILITY_MUTATION, false)
    } else {
      commit(SET_SELECTED_STATION_MUTATION, null)
      commit(SET_AUTH_MENU_VISIBILITY_MUTATION, false)
      commit(SET_MENU_VISIBILITY_MUTATION, true)
    }
  },
  async [TOGGLE_AUTH_MENU_ACTION] ({ state, commit }) {
    if (state.authMenuVisibility) {
      commit(SET_AUTH_MENU_VISIBILITY_MUTATION, false)
    } else {
      commit(SET_SELECTED_STATION_MUTATION, null)
      commit(SET_MENU_VISIBILITY_MUTATION, false)
      commit(SET_AUTH_MENU_VISIBILITY_MUTATION, true)
    }
  },
  async [ADD_NEW_STATION_ACTION] ({ commit, state }, data) {
    let station = {
      schedule: [],
      nearbyPlaces: [],
      connectors: [],
      photos: [],
      name: '',
      site: '',
      description: '',
      ...data
    }
    if (state.newStation) {
      station = {
        ...state.newStation,
        ...station
      }
    }
    // console.log(station)
    commit(SET_NEW_STATION_MUTATION, station)
  },
  async [UPDATE_NEW_STATION_ACTION] ({ commit, state }, data) {
    const station = { ...state.newStation }
    const stationField = data.updatedStationField

    if ('connectorId' in data) {
      station[stationField][data.connectorId] = data.values
    } else {
      station[stationField] = data.values
    }

    commit(SET_NEW_STATION_MUTATION, station)
  },
  async [SEND_STATION_ACTION] ({ state }) {
    try {
      const station = JSON.stringify(state.newStation)
      await api.sendStation(station)
      // console.log(response)
    } catch (err) {
      console.log(err)
    }
  }
}
