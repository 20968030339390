export default {
  header: {
    login: 'Увійти',
    about_company: 'Про компанію',
    about_service: 'Про послугу',
    buy_station: 'Купити зарядну станцію'
  },
  auth_popup: {
    authorization: 'Авторизація',
    reset_password_label: 'Відновити пароль',
    reset_password: 'Відновити пароль',
    register: 'Зареєструватися',
    registration: 'Регістрірація',
    our_application: 'Наш додаток',
    agree_to_terms: 'Згоден з <a target="_blank" href="{ url }" class="rools_link"> правилами</a>',
    form: {
      login: 'Email або номер телефону',
      email: 'Email',
      password: 'Пароль',
      repeat_password: 'Повторіть пароль',
      name: 'Імя',
      second_name: 'Прізвище',
      phone_number: 'Номер телефону',
      vin: 'VIN',
      plate: 'Plate',
      auto_vendor: 'Auto vendor',
      auto_model: 'Auto model',
      secret_word: 'Secret word'
    }
  },
  language: {
    name: 'Мова'
  },
  profile: {
    profile_title: 'Профіль',
    hello_word: 'Вітаю, ',
    balance: 'Баланс: '
  },
  main_menu: {
    title: 'Меню',
    main_page: 'Головна',
    charge_page: 'Зарядка',
    cars_page: 'Мої авто',
    statistic_page: 'Статистика',
    sales_page: 'Продажі',
    my_stations_page: 'Моі станції',
    comments_page: 'Коментарі',
    new_stations_page: 'Нові станції',
    control_room_page: 'Диспетчерська'
  },
  mobile_modal: {
    app_is_better: 'З додатком зручніше',
    work_from_phone: 'Качайте і працюйте з телефону!'
  },
  station_sidebar: {
    go_to_site: 'Перейти на сайт',
    open: 'Відкрито',
    near: 'Поруч',
    schedule: {
      all_day: 'Цілодобово'
    },
    comments: {
      month_ago: 'місяць тому',
      year_ago: 'рік тому',
      years_ago: 'років тому',
      'two-four_months_ago': 'місяці тому',
      'five-twelve_months_ago': 'місяців тому',
      today: 'сьогодні',
      days_ago: 'днів тому',
      cancel_button: 'Відмінити',
      send_button: 'Відправити'
    },
    rates: {
      time: 'год',
      parking: 'хв',
      kWh: 'кВт*год'
    }
  },
  filters: {
    kW: 'кВт',
    sessions: 'Сесій',
    charged: 'Заряджено'
  },
  search: {
    title: 'Пошук',
    search_field_placeholder: 'Напишіть назву зарядної станції'
  },
  statuses: {
    available: 'Доступний',
    preparing: 'Заряджає',
    charging: 'Заряджає',
    finishing: 'Заряджає',
    suspendedEVSE: 'Заряджає',
    suspendedEV: 'Заряджає',
    occupied: 'Заряджає',
    unavailable: 'Недоступний',
    faulted: 'Несправний',
    unknown: 'Невідомий'
  },
  edit_station_info: {
    edit_station_name: 'Назва станції',
    edit_station_connectors_name: 'Конектора',
    edit_address_station: 'Адреса станції',
    edit_station_description: 'Опис',
    edit_site_station: 'Сайт станції (компанії)',
    edit_button: 'Редагувати',
    incorrect_name: "Неправильне ім'я",
    incorrect_address: 'Неправильна адреса',
    edit_photo: 'Фотографії',
    save_button: 'Зберегти'
  },
  edit_stations_images: {
    edit_photo: 'Додати фото'
  },
  edit_station_connectors: {
    edit_connector: 'Додати конектор'
  }
}
