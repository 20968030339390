<template>
  <ModalWrapper
    :show-backdrop="$store.state.modals.chooseConnectorModalVisibility"
    @closeModal="close"
  >
    <div class="choose-modal_wrap">
      <div class="header_modal">
        <div class="close" @click="close"></div>
        <h2 class="modal_title">Добавление коннектора</h2>
      </div>
      <div class="modal_content">
        <div class="modal_connector_item">
          <h3 class="modal_connector_item_title">Коннекторы AC</h3>
          <div class="row">
            <div
              class="item connector_item"
              :class="{ active: selectedTypes.includes(type) }"
              @click="selectType(type)"
              v-for="type in ac"
              :key="type"
            >
              <div
                class="connector_item_img"
                :style="getConnectorStyles(type)"
              ></div>
              <span class="connector_item_name">{{ getConnectorName(type) }}</span>
            </div>
          </div>
        </div>
        <div class="modal_connector_item">
          <h3 class="modal_connector_item_title">Коннекторы DC</h3>
          <div class="row">
            <div
              class="item connector_item"
              :class="{ active: selectedTypes.includes(type) }"
              @click="selectType(type)"
              v-for="type in dc"
              :key="type"
            >
              <div
                class="connector_item_img"
                :style="getConnectorStyles(type)"
              ></div>
              <span class="connector_item_name">{{ getConnectorName(type) }}</span>
            </div>
          </div>
        </div>
        <div class="modal_connector_item" :class="{ active: activeSockets }">
          <h3 class="modal_connector_item_title" @click="socketsOpenList">
            Розетки
            <img
              src="../../../../assets/img/down_arrow.svg"
              alt=""
              class="img_arrow"
            />
          </h3>
          <div class="row sockets">
            <div
              class="item connector_item"
              :class="{ active: selectedTypes.includes(type) }"
              @click="selectType(type)"
              v-for="type in sockets"
              :key="type"
            >
              <div
                class="connector_item_img"
                :style="getConnectorStyles(type)"
              ></div>
              <span class="connector_item_name">{{ getConnectorName(type) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons_modal footer_modal">
        <button class="cancel_button" @click="close">Отменить</button>
        <button class="submitted_btn" @click="save">Готово</button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapState } from 'vuex'
import ModalWrapper from '@/components/ModalWrapper'
import {
  UPDATE_NEW_STATION_ACTION,
  SET_MODAL_VISIBILITY_MUTATION
} from '@/store/constants'
import { acTypesId, connectorTypes, dcTypesId, socketTypesId } from '@/data'

export default {
  name: 'ChooseConnectorModal',
  components: {
    ModalWrapper
  },
  beforeMount () {
    if (this.newStation.connectors.length > 0) {
      this.selectedTypes = this.newStation.connectors.map(
        (connector) => connector.type
      )
    }
  },
  data: () => ({
    selectedTypes: [],
    activeSockets: false,
    ac: acTypesId.slice(),
    dc: dcTypesId.slice(),
    sockets: socketTypesId.slice()
  }),
  methods: {
    socketsOpenList () {
      this.activeSockets = !this.activeSockets
    },
    selectType (type) {
      if (!this.selectedTypes.includes(type)) {
        this.selectedTypes.push(type)
      } else {
        this.selectedTypes = this.selectedTypes.filter((t) => t !== type)
      }
    },
    getConnectorStyles (type) {
      return {
        mask: `url(/v2/connectors_and_sockets/${connectorTypes[type].imgName}) no-repeat center / contain`,
        '-webkit-mask': `url(/v2/connectors_and_sockets/${connectorTypes[type].imgName}) no-repeat center / contain`
      }
    },
    getConnectorName (type) {
      return connectorTypes[type].shortName || connectorTypes[type].name
    },
    save () {
      if (this.selectedTypes.length) {
        const connectors = this.selectedTypes.map(
          (type) => ({ type, electricity: '1.7', time: '0', parking: '0' })
        )
        this.$store.dispatch(UPDATE_NEW_STATION_ACTION, {
          updatedStationField: 'connectors',
          values: connectors
        })
      }
      this.close()
    },
    close () {
      this.$store.commit(SET_MODAL_VISIBILITY_MUTATION, {
        modalName: 'chooseConnectorModalVisibility',
        flag: false
      })
    }
  },
  computed: mapState(['newStation'])
}
</script>

<style scoped lang="scss">
</style>
