export const languages = {
  en: {
    name: 'English',
    iconName: 'gb-nir'
  },
  ua: {
    name: 'Українська',
    iconName: 'ua'
  },
  de: {
    name: 'Deutsche',
    iconName: 'de'
  },
  ru: {
    name: 'Русский',
    iconName: 'ru'
  },
  pt: {
    name: 'Português',
    iconName: 'pt'
  },
  pl: {
    name: 'Polskie',
    iconName: 'pl'
  }
}
