import Vue from 'vue'

Vue.directive('dropdown', {
  inserted (el) {
    document.addEventListener('click', () => {
      if (el.classList.contains('open')) {
        el.classList.remove('open')
      }
    })

    el.addEventListener('click', (e) => {
      e.stopImmediatePropagation()

      if (el.classList.contains('open')) {
        if (!el.classList.contains('no-close')) {
          el.classList.remove('open')
        }
      } else {
        Array.from(document.querySelectorAll('.dropdown.open')).forEach((node) => {
          node.classList.remove('open')
        })
        el.classList.add('open')
      }
    })
  }
})
